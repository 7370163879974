import axios from "axios";

const useUserProviders = () => {
  const postCouponProvider = (data) => {
    return axios({
      method: "post",
      url: "/api/user/apply-coupon",
      data,
    });
  };

  const postCouponRedeemProvider = (data) => {
    return axios({
      method: "post",
      url: "/api/data-redeem",
      data,
    });
  };

  const getCouponsProvider = (id) => {
    return axios({
      method: "get",
      url: `/api/data-assigned-coupons-user/${id}`,
    });
  };

  const loginProvider = (data) => {
    return axios({
      method: "post",
      url: "/api/user/process",
      data,
    });
  };

  const fetchPendingCouponsProvider = (userID) => {
    return axios({
      method: "get",
      url: `/api/data-assigned-coupons-user/${userID}`,
    });
  };

  const fetchUserProfileProvider = (userID) => {
    return axios({
      method: "get",
      url: `/api/data-user/${userID}`,
    });
  };

  const fetchRedeemedCouponsProvider = (userID) => {
    return axios({
      method: "get",
      url: `/api/data-assigned-coupons-user/${userID}`,
    });
  };

  return {
    postCouponProvider,
    getCouponsProvider,
    loginProvider,
    fetchPendingCouponsProvider,
    fetchUserProfileProvider,
    fetchRedeemedCouponsProvider,
    postCouponRedeemProvider,
  };
};

export default useUserProviders;
