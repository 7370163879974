import useNavigation from "./footer";
import useHeader from "./header";
import useScroll from "./scrollToTop";
import useYupValidationResolver from "./yup";
import useCodeVerify from "./codeVerify";

const useComponentsHooks = () => {
  return {
    useNavigation,
    useHeader,
    useScroll,
    useYupValidationResolver,
    useCodeVerify,
  };
};

export default useComponentsHooks;
