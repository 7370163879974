import useControllers from "../../../controllers";
import useModels from "../../../models";
import useStrings from "../../../strings";


const useOnPremiseReducer = () => {
  const { useGeneralHooks } = useControllers()
  const { useUtils } = useGeneralHooks()
  const { useCreateReducer } = useUtils()
  const { createReducer } = useCreateReducer()

  const { useInitialStates } = useModels()
  const { useOnPremiseInitialState } = useInitialStates()
  const { onPremiseInitialState } = useOnPremiseInitialState()

  const { useTypes } = useStrings()
  const { useOnPremiseTypes } = useTypes()
  const { GET_EXPIRED_INFO } = useOnPremiseTypes()

  const onPremise = createReducer(onPremiseInitialState, {
    [GET_EXPIRED_INFO](state, action) {
      return {
        ...state,
        ...action.payload
      }  
    }
  })

  return { onPremise }
}

export default useOnPremiseReducer