import useSso from "./sso";
import useDataLayers from "./dataLayers";
import useUtils from "./utils";

const useGeneralHooks = () => {
  return {
    useSso,
    useDataLayers,
    useUtils,
  };
};

export default useGeneralHooks;
