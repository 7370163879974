import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useModels from "../../../../models";
import useApi from "../../../../api";

const useLogin = (watch) => {
  const { useSelectors } = useModels();
  const { useSelector, useCouponSelectors, useSessionSelectors, useUtmSelectors } = useSelectors();
  const {
    couponSelector,
    couponIndicativesSelector,
    couponLengthPhoneNumberSelector,
  } = useCouponSelectors();
  const { sessionSelector } = useSessionSelectors();
  const { utmSelector } = useUtmSelectors();
  const { value: UTM } = useSelector(utmSelector);
  const { useActions } = useApi();
  const { dispatch, useUserActions, useSessionActions } = useActions();
  const { actLogin } = useUserActions();
  const { actSetSessionProcess } = useSessionActions();

  const [ssoLogin, setSsoLogin] = useState(false);
  const [phoneLength, setPhoneLength] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const couponId = useSelector(couponSelector);
  const session = useSelector(sessionSelector);
  const indicatives = useSelector(couponIndicativesSelector);
  const lengthPhoneNumbers = useSelector(couponLengthPhoneNumberSelector);
  const watchSelectIndicative = watch("indicative", indicatives[0]);

  const getLengthOfIndicativeSelected = () => {
    const indicativeIndexSelected = indicatives.findIndex(
      (element) => element.value === watchSelectIndicative.value
    );
    return (
      parseInt(lengthPhoneNumbers[indicativeIndexSelected]) -
      indicatives[indicativeIndexSelected].value.length
    );
  };

  useEffect(() => {
    setPhoneLength(getLengthOfIndicativeSelected());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchSelectIndicative]);

  useEffect(() => {
    setSsoLogin(location?.state?.sso);
    dispatch(actSetSessionProcess({ process: "login" }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (ssoLogin) {
      window.ssoApp.showApp("auth-login");
    }
  }, [ssoLogin]);

  const onLogIn = (data) => {
    const { indicative, phone } = data;
    if (ssoLogin) {
      window.ssoApp.showApp("auth-login");
    } else {
      const dataLogin = {
        phone: parseInt(`${indicative.value}${phone}`),
        op: "login",
        UTM,
      };
      dispatch(
        actLogin(dataLogin, (res) => {
          history.push(`/${session.brand}/otp${UTM && "?" + UTM}`);
        })
      );
    }
  };

  return {
    onLogIn,
    indicatives,
    couponId,
    ssoLogin,
    session,
    phoneLength,
  };
};

export default useLogin;
