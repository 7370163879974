import useComponents from "./components";
import useScreens from "./screens";
import useLayouts from "./layouts";

/*
  useViews => HOOK
  - This hook exports everything related to UI interface of application: Screens, Components, Layouts, etc
  - Rules:
    1. All views has exported with lazy
    2. Every view name starts with capital letter
*/
const useViews = () => {
  return {
    useScreens,
    useComponents,
    useLayouts,
  };
};

export default useViews;
