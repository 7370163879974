import useModels from "../../../models";
import _ from "lodash";

const useNavigation = () => {
  const { useSelectors } = useModels();
  const { useSelector, useCouponSelectors } = useSelectors();
  const { brandSelector } = useCouponSelectors();
  const { terms, policys, facebook, twitter, instagram, youtube, tiktok, colors } = useSelector(
    brandSelector
  );

  const goToTC = terms;
  const goToPP = policys;
  const goToFacebook = facebook;
  const goToTwitter = twitter;
  const goToInstagram = instagram;
  const goToYoutube = youtube;
  const goToTiktok = tiktok;

  const socialMediaLinks = [goToFacebook, goToTwitter, goToInstagram, goToYoutube, goToTiktok];

  const handleRedirect = (link) => {
    window.open(link, "_blank");
  };

  const handleValidateHasLinks = () => {
    return _.some(socialMediaLinks, (currentValue) => currentValue?.length > 0);
  };

  return {
    goToTC,
    goToPP,
    goToFacebook,
    goToTwitter,
    goToInstagram,
    goToYoutube,
    goToTiktok,
    colors,
    handleRedirect,
    handleValidateHasLinks,
  };
};

export default useNavigation;
