import { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import Treasure from "td-js-sdk";
import useControllers from "../..";
import useModels from "../../../models";
import useApi from "../../../api";
import useViews from "../../../views";
import _ from "lodash";

const useFormCoupon = ({ watch, setError, errors }) => {
  const { useSelectors } = useModels();
  const { useComponents } = useViews();
  const { customToast, Toast } = useComponents();
  const {
    useSelector,
    useUserSelectors,
    useCouponSelectors,
    useSessionSelectors,
    useUtmSelectors,
    useGeneralSelectors,
  } = useSelectors();
  const { userDataSelector } = useUserSelectors();
  const userInfo = useSelector(userDataSelector);

  const {
    couponSelectorAux,
    couponIndicativesSelector,
    couponLengthPhoneNumberSelector,
    brandSelector,
    emailDomainsSelector,
  } = useCouponSelectors();
  const { sessionSelector } = useSessionSelectors();
  const { utmSelector } = useUtmSelectors();
  const { provincesSelector } = useGeneralSelectors();

  const { on_premise } = useSelector(brandSelector);

  const { useActions } = useApi();
  const {
    dispatch,
    useCouponActions,
    useSessionActions,
    useUserActions,
    useOtpActions,
    useGeneralActions,
  } = useActions();
  const { actGetCouponById, actRegister, actValidateCoupon } = useCouponActions();
  const { actGetProvinces } = useGeneralActions();
  const { actSetSessionProcess } = useSessionActions();
  const { actPostNewCoupon, actDeleteCouponRedeem, actSaveUserProfile } = useUserActions();
  const { actSaveToken } = useOtpActions();
  const { useGeneralHooks } = useControllers();
  const { useDataLayers } = useGeneralHooks();
  const {
    gtmValidateRegisterInteraction,
    gtmValidateFormInteraction,
    gtmOnPremiseFormFocus,
    gtmOnPremiseSuccesForm,
    gtmOnPremiseErrorForm,
  } = useDataLayers();

  const location = useLocation();
  const { push } = useHistory();
  const [phoneLength, setPhoneLength] = useState(null);
  const [onSubimt, setOnSubimt] = useState(false);
  const [codeRegister, setCodeRegister] = useState(false);
  const [codeRedi, setCodeRedi] = useState(false);
  const [errorMsg] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [birthdayday, setBirthdayday] = useState("");
  const [birthdaymont, setBirthdaymont] = useState("");
  const [birthdayano, setBirthdayano] = useState("");
  const [province, setProvince] = useState("");
  const [phone, setPhone] = useState("");

  const [emailLength, setEmailLength] = useState(0);
  const { brandName } = useParams();

  const session = useSelector(sessionSelector);
  const coupon = useSelector(couponSelectorAux);
  const indicatives = useSelector(couponIndicativesSelector);
  const lengthPhoneNumbers = useSelector(couponLengthPhoneNumberSelector);
  const { name: brandNameCoupon } = useSelector(brandSelector);
  const { value: UTM } = useSelector(utmSelector);
  const domains = useSelector(emailDomainsSelector);
  const provinces = useSelector(provincesSelector);
  const watchSelectIndicative = watch("indicative", indicatives[0]);

  const getLengthOfIndicativeSelected = () => {
    const indicativeIndexSelected = indicatives.findIndex(
      (element) => element.value === watchSelectIndicative.value
    );
    return (
      parseInt(lengthPhoneNumbers[indicativeIndexSelected]) -
      indicatives[indicativeIndexSelected].value.length
    );
  };

  const handleSuccessProvince = () => {
    document.querySelector("body").style = "";
  };

  useEffect(() => {
    dispatch(actDeleteCouponRedeem());
    dispatch(actGetProvinces(handleSuccessProvince));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPhoneLength(getLengthOfIndicativeSelected());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchSelectIndicative]);

  useEffect(() => {
    if (email?.includes("@")) {
      if (email.length > emailLength) {
        const emailAddress = email.split("@")[0];
        const emailDomain = email.split("@")[1];
        if (emailDomain?.length > 0) {
          const suggestion = domains.filter((d) =>
            d.toLowerCase().startsWith(emailDomain.toLowerCase())
          )[0];
          if (suggestion) {
            const newEmail = `${emailAddress}@${suggestion.toLowerCase()}`;
            setEmail(newEmail);
            setEmailLength(newEmail.length);
          }
        }
      } else {
        setEmailLength(email.length);
      }
    }
  }, [email]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseCodeRegister = () => {
    setCodeRegister(false);
  };
  const handleCloseCodeRedi = () => {
    setCodeRedi(false);
  };

  const submit = (data) => {
    const cookie = new Treasure({
      database: "cuponera_bees",
      writeKey: process.env.REACT_APP_API_TREASURE_KEY,
    });

    const { email, name, lastName, phone, tyc, indicative, news, age, monn, day, city } = data;
    const birthday = `${age}-${monn}-${day}`;

    let id;
    if (coupon.id) {
      id = coupon.id;
    }

    gtmValidateRegisterInteraction({
      eventAction: "click",
      tech: "LoginAPI",
      brandName: brandNameCoupon,
    });

    const post = {
      id_coupon: parseInt(id),
      name,
      last_name: lastName,
      phone: parseInt(`${indicative.value}${phone}`),
      email,
      enrollment: tyc ? 1 : 0,
      marketing_assent: news ? 1 : 1,
      cookie_td: cookie.client.track.uuid,
      birthday,
      op: "register",
      UTM,
      city: city.value,
    };

    dispatch(
      actRegister(post, (response) => {
        setOnSubimt(true);

        if (on_premise === "1") {
          gtmOnPremiseSuccesForm(response.user_id, "false", "boton_registrarme");
        }

        gtmValidateRegisterInteraction({
          eventAction: "success",
          tech: "LoginAPI",
          brandName: brandNameCoupon,
        });

        dispatch(
          actSaveUserProfile({
            name: name,
            email: email,
            phone: phone,
            birtdayDate: birthday,
            province: city.value,
            userId: response.user_id,
          })
        );

        switch (response.status_code) {
          case "011":
          case "019":
            push(`/${brandName}/otp${UTM && "?" + UTM}`, {
              couponId: id,
              userId: response.user_id,
              email: email,
              phone: phone,
              fromRegisterForm: true,
            });

            break;
          case "001":
            //if (!coupon.brand.otp) {
            if (true) {
              dispatch(
                actSaveToken({ token: response.token }, id, () => {
                  const couponFromRegister = {
                    id_user: response.user_id,
                    id_coupon: parseInt(id),
                    UTM,
                  };
                  dispatch(
                    actPostNewCoupon(couponFromRegister, (res) => {
                      switch (res.status_code) {
                        case "004":
                          if (res.url) {
                            window.location.href = res.url;
                            //console.log(res.url);
                          } else {
                            push(`/${session.brand}/success${UTM && "?" + UTM}`, {
                              ...res,
                              stateQR: "Nuevo",
                            });
                          }
                          gtmValidateFormInteraction({
                            eventAction: "Success",
                            tech: "LoginAPI",
                            brandName: brandNameCoupon,
                          });
                          break;
                        default:
                          break;
                      }
                    })
                  );
                })
              );
            } else {
              push(`/${brandName}/otp${UTM && "?" + UTM}`, {
                couponId: id,
                userId: response.user_id,
                email: email,
                phone: phone,
                fromRegisterForm: true,
              });
            }
            break;
          case "002":
            setCodeRegister(response);
            setOnSubimt(false);
            break;
          case "003":
            setCodeRedi(response);
            setOnSubimt(false);
            break;
          default:
            setOnSubimt(false);
            break;
        }
      })
    );
  };

  const goLogin = () => {
    // gtmGoToLogIn1dot1(name);
    push(`/user/login${UTM && "?" + UTM}`);
  };

  const handleKeyPress = (e, digits) => {
    if (e.target.value.length === digits - 1) {
      setTimeout(() => {
        const form = e.target.form;
        const index = [...form].indexOf(e.target);
        form.elements[index + 1].focus();
        e.preventDefault();
      }, 100);
    }
  };

  useEffect(() => {
    const callToast = (text = "", listOfErrors) => {
      customToast.error(<Toast text={text} listOfErrors={listOfErrors} state={"error"} />, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      gtmOnPremiseErrorForm("True", text, "boton_registrarme");
    };
    dispatch(
      actValidateCoupon({ id_coupon: coupon.id }, (response) => {
        switch (response.status_code) {
          case "047":
            setOnSubimt(false);
            break;

          default:
            setOnSubimt(true);
            callToast(response.message);
            break;
          // not handler error
        }
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOnSubimt, coupon.id, dispatch]);

  useEffect(() => {
    if (location && location.state && location.state.id) {
      dispatch(actGetCouponById({ id: location.state.id }));
    }
    dispatch(actSetSessionProcess({ process: "register" }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, dispatch]);

  //Preloader form
  useEffect(() => {
    setEmail(userInfo.email);
    setName(userInfo.name);
    setPhone(userInfo.phone);

    const brthdayDate = _.split(userInfo.birtdayDate, "-");

    setBirthdayday(brthdayDate[2]);
    setBirthdaymont(brthdayDate[1]);
    setBirthdayano(brthdayDate[0]);

    const defaultValueProvince = _.find(provinces, function (province) {
      return province.value === userInfo.province;
    });

    setProvince(defaultValueProvince);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const handleFocusFields = (event, step, name) => {
    gtmOnPremiseFormFocus("true", step, name, "boton_registrarme");
  };

  useEffect(() => {
    let errorString = "";
    _.forEach(errors, (error) => {
      console.log(error);
      errorString += `${error.message},`;
    });
    gtmOnPremiseErrorForm("True", errorString, "boton_registrarme");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return {
    ...coupon,
    codeRegister,
    codeRedi,
    handleCloseCodeRegister,
    handleCloseCodeRedi,
    submit,
    goLogin,
    errorMsg,
    indicatives,
    phoneLength,
    onSubimt,
    email,
    name,
    phone,
    birthdayday,
    birthdaymont,
    birthdayano,
    province,
    setEmail,
    setEmailLength,
    provinces,
    handleKeyPress,
    handleFocusFields,
  };
};

export default useFormCoupon;
