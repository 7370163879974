import useApi from '../../../api'
import useStrings from '../../../strings';

const useOnPremiseActions = () => {
  const { useServices } = useApi()
  const { useOnPremiseServices } = useServices()
  const { checkStoreService } = useOnPremiseServices()

  const { useTypes } = useStrings()
  const { useOnPremiseTypes } = useTypes()
  const { GET_EXPIRED_INFO } = useOnPremiseTypes()

  const actCheckStore = ({ idStore, idUser, idCoupon, couponCode }, onSuccess, onError) => async (dispatch) => {
    try {
      const res = await checkStoreService({ idStore, idUser, idCoupon: Number(idCoupon), code: couponCode })
      if (res.data.status_code === '019') {
        dispatch({
          type: GET_EXPIRED_INFO,
          payload: {
            redeemDate: new Date().toString(),
            minutesToExpire: Number(res.data.minutes_to_expire),
            code: couponCode,
            expired: false
          }
        })
        onSuccess && onSuccess()
      } else if (res.data.status_code === '039') {
        dispatch({
          type: GET_EXPIRED_INFO,
          payload: {
            redeemDate: new Date().toString(),
            minutesToExpire: null,
            code: couponCode,
            expired: true
          }
        })
        onSuccess && onSuccess()
      }
    } catch (error) {
      onError && onError(error)
    }
  }

  return { actCheckStore }
}

export default useOnPremiseActions