import { useSelector } from "react-redux";
import useAgeGateSelectors from "./ageGate";
import useCouponSelectors from "./coupon";
import useGeneralSelectors from "./general";
import useMapSelectors from "./map";
import useOtpSelectors from "./otp";
import useUserSelectors from "./user";
import useSessionSelectors from "./session";
import useUtmSelectors from "./utm";
import useOnPremiseSelectors from "./onPremise";
// import useSelectorExample from "./selectorExample";

/*
  useSelectors => HOOK
  - This hook exports all selectors
  - rules:
    1. The useSelector is only imported here and exported in useSelectors
    2. Selector hooks must be called like this: prefix *use* + Name related to selector + Suffix *Selectors* (in prural)
      - Example: useUserSelectors
    3. Each action has 1 or more selectors that must be named like this: Name of selector + suffix *Selector*
      - Example: perfilUserSelector

  - All selectors was created with a createSelector function:
    path of this function => src/controllers/generalHooks/utils/createSelector/index.js
    1. That libray if for more performance and efficient to selectors
    2. A selector is not recomputed unless one of its arguments changes
*/
const useSelectors = () => {
  return {
    useSelector,
    // useSelectorExample,
    useCouponSelectors,
    useGeneralSelectors,
    useMapSelectors,
    useUserSelectors,
    useAgeGateSelectors,
    useOtpSelectors,
    useSessionSelectors,
    useUtmSelectors,
    useOnPremiseSelectors
  };
};

export default useSelectors;
