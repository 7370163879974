import useToggleCoupons from "./toggleCoupons";
import useGenerateCoupon from "./generateCoupon";
import useCouponDetails from "./couponDetails";

const useMenu = () => {
  return {
    useToggleCoupons,
    useGenerateCoupon,
    useCouponDetails,
  };
};

export default useMenu;
