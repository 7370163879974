import useControllers from "../../../controllers";
import useStrings from "../../../strings";
import useInitialStates from "../../initialStates";

const useSessionReducers = () => {
  const { useTypes } = useStrings();
  const { useSessionTypes } = useTypes();
  const { SET_SESSION_BRAND, SET_SESSION_PROCESS } = useSessionTypes();
  const { useSessionInitialStates } = useInitialStates();
  const { sessionInitialState } = useSessionInitialStates();
  const { useGeneralHooks } = useControllers();
  const { useUtils } = useGeneralHooks();
  const { useCreateReducer } = useUtils();
  const { createReducer } = useCreateReducer();

  const session = createReducer(sessionInitialState, {
    [SET_SESSION_BRAND](state, action) {
      return {
        ...state,
        brand: action.payload,
      };
    },
    [SET_SESSION_PROCESS](state, action) {
      return {
        ...state,
        process: action.payload,
      };
    },
  });
  return { session };
};

export default useSessionReducers;
