import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useOtpServices = () => {
  const { useOtpProviders } = useProviders();
  const { validate, send } = useOtpProviders();

  const validateOtp = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(validate(data)));
      } catch (e) {
        reject(e);
      }
    });
  };
  const sendOtp = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(send(data)));
      } catch (e) {
        reject(e);
      }
    });
  };

  return {
    validateOtp,
    sendOtp,
  };
};

export default useOtpServices;
