import useCouponProviders from "./coupon";
import useSplashProviders from "./splash";
import useOtpProviders from "./otp";
import useShopProviders from "./shop";
import useUserProviders from "./user";
import useSsoProviders from "./sso";
import useSplashSmsProviders from "./splashSms";
import useOnPremiseProviders from "./onPremise";
import useGeneralProviders from "./general";
/*
  useProviders => HOOK
  - This hook exports all providers
  - rules:
    1. Provider hooks must be called like this: prefix *use* + Name related to provider + Suffix *Providers* (in prural)
      - Example: useUserProviders
    3. Each provider has 1 or more providers that must be named like this: Name related to provider + Suffix *Provider*
      - Example: loginUserProvider
*/
const useProviders = () => {
  return {
    useCouponProviders,
    useOtpProviders,
    useShopProviders,
    useUserProviders,
    useSplashProviders,
    useSsoProviders,
    useSplashSmsProviders,
    useOnPremiseProviders,
    useGeneralProviders,
  };
};

export default useProviders;
