import useControllers from "../../../controllers";
import _ from "lodash";

const useCouponSelectors = () => {
  const { useGeneralHooks } = useControllers();
  const { useUtils } = useGeneralHooks();
  const { useCreateSelector } = useUtils();
  const { createSelector } = useCreateSelector();

  const brandSelector = createSelector(
    (state) => state.coupon,
    (coupon) => {
      return {
        ...coupon.data.brand,
        ...coupon.data,
      };
    }
  );

  const couponParamsToShowSelector = createSelector(
    (state) => state.couponToShow,
    (couponToShow) => {
      return {
        ...couponToShow.data,
        id: couponToShow.id,
      };
    }
  );

  const couponToShowSelector = createSelector(
    (state) => state.couponToShow,
    (couponToShow) => {
      return {
        ...couponToShow.couponInfo,
      };
    }
  );

  const couponSelectorAux = createSelector(
    (state) => state.coupon,
    (state) => state.params,
    (coupon, params) => {
      const data = coupon.data;
      return {
        ...data,
        ...params,
      };
    }
  );

  const couponIndicativesSelector = createSelector(
    (state) => state.coupon,
    (coupon) => {
      const indicatives = coupon.data.brand.indicatives;

      try {
        return _.map(indicatives, (ind) => {
          return { value: ind, label: `+${ind}` };
        });
      } catch (err) {
        return [];
      }
    }
  );

  const couponLengthPhoneNumberSelector = createSelector(
    (state) => state.coupon,
    (coupon) => {
      const lengthPhoneNumber = coupon.data.brand.tamanos_telefono;

      try {
        return lengthPhoneNumber;
      } catch (err) {
        return [];
      }
    }
  );

  const colorSelector = createSelector(
    (state) => state.coupon,
    (coupon) => {
      try {
        return {
          ...coupon.data.brand,
          ...coupon.data,
        };
      } catch (err) {
        return null;
      }
    }
  );

  const couponListSelector = createSelector(
    (state) => state.couponList,
    (couponList) => {
      let data = {
        redeemed: [],
        pending: [],
      };
      if (couponList.assignments && couponList.assignments.length > 0) {
        data = couponList.assignments.reduce(
          (acc, cv) => {
            switch (cv.status) {
              case "redeemed":
                acc.redeemed.push(cv);
                break;
              case "expired":
                acc.redeemed.push(cv);
                break;
              case "pending":
                acc.pending.push(cv);
                break;
              case "assigned":
                acc.pending.push(cv);
                break;
              default:
                break;
            }

            return acc;
          },
          {
            redeemed: [],
            pending: [],
          }
        );
      }

      return data;
    }
  );

  const couponSelector = createSelector(
    (state) => state.coupon,
    (coupon) => {
      const data = coupon.data;
      return {
        ...data,
      };
    }
  );

  const couponRedeemSelector = createSelector(
    (state) => state.postCouponRedeem,
    (coupon) => {
      return {
        ...coupon,
      };
    }
  );

  const emailDomainsSelector = createSelector(
    (state) => state.coupon,
    (coupon) => {
      return [...coupon.data.dominios_correos];
    }
  );

  return {
    brandSelector,
    couponSelector,
    couponSelectorAux,
    colorSelector,
    couponListSelector,
    couponIndicativesSelector,
    couponLengthPhoneNumberSelector,
    couponRedeemSelector,
    couponParamsToShowSelector,
    couponToShowSelector,
    emailDomainsSelector,
  };
};

export default useCouponSelectors;
