import { useParams, useHistory } from "react-router-dom";
import { useEffect } from "react";
// import { gtmThanksForParticipating } from "../../../datalayers";
// import { couponEventCategorySelector } from "../selectors";
import useSelectors from "../../../models/selectors";
import useApi from "../../../api";
import useGeneralHooks from "../../generalHooks";

const useSuccessRefresh = () => {
  const { useActions } = useApi();
  const {
    dispatch,
    useUserActions,
    useCouponActions,
    useSessionActions,
    useUtmActions,
  } = useActions();
  const { actPostCouponRedeem } = useUserActions();
  const { actGetCouponById, actDeleteCouponToShow } = useCouponActions();
  const { actSetSessionBrand, actSetSessionProcess } = useSessionActions();
  const { actSetUtm } = useUtmActions();
  const { useSelector, useCouponSelectors, useUserSelectors } = useSelectors();
  const { profileSelector } = useUserSelectors();
  const { couponRedeemSelector, couponSelectorAux } = useCouponSelectors();
  const { useDataLayers, useUtils } = useGeneralHooks();
  const { useQueryUtm } = useUtils();
  const { getQueryUtm } = useQueryUtm();
  const { gtmTakePart, gtmCouponRedeemed } = useDataLayers();

  const history = useHistory();
  const { brandNameSms, key } = useParams();
  const couponRedeemStatus = useSelector(couponRedeemSelector);
  const user = useSelector(profileSelector);
  const couponParams = useSelector(couponSelectorAux);
  const date = new Date(parseInt(couponRedeemStatus.redeemed_date) * 1000);
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const handleOnSuccessCouponRedeem = (data) => {
    dispatch(actGetCouponById({ id: data.coupon_id }));
    onSuccesGetCouponByIdFromRedeem(data);
  };

  const onSuccesGetCouponByIdFromRedeem = (data) => {
    dispatch(actSetSessionBrand({ brand: brandNameSms }));
    data.status === "assigned" &&
      history.push(`/${brandNameSms}/success${getQueryUtm && "?" + getQueryUtm}`);
  };

  useEffect(() => {
    dispatch(actSetUtm({ utm: getQueryUtm }));
    dispatch(actSetSessionProcess({ process: "" }));
    dispatch(actDeleteCouponToShow());
    dispatch(actPostCouponRedeem({ id: key, UTM: getQueryUtm }, handleOnSuccessCouponRedeem));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    couponRedeemStatus.coupon_code &&
      gtmCouponRedeemed({
        couponCode: couponRedeemStatus.coupon_code,
        brandName: couponParams.brand.name,
        userID: user.id || couponRedeemStatus.uid,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponRedeemStatus.coupon_code]);

  const goBackToStart = () => {
    gtmTakePart({ couponCode: couponRedeemStatus.coupon_code, brandName: couponParams.brand.name });
    history.push(`/${brandNameSms}/form${getQueryUtm && "?" + getQueryUtm}`);
  };

  return {
    goBackToStart,
    day,
    month,
    year,
    couponRedeemStatus,
  };
};

export default useSuccessRefresh;
