import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useSplashServices = () => {
  const { useSplashProviders } = useProviders();
  const { getBrandId } = useSplashProviders();

  const getBrandIdService = ({ brandName, UTM }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getBrandId({ brandName, UTM })));
      } catch (e) {
        reject(e);
      }
    });
  };

  return { getBrandIdService };
};

export default useSplashServices;
