import useStrings from "../../../strings";
import useServices from "../../services";

const useUserActions = () => {
  const { useTypes } = useStrings();
  const { useUserTypes, useCouponTypes } = useTypes();
  const {
    SAVE_USER_PROFILE,
    SAVE_USER_PROFILE_SUCCESS,
    SAVE_USER_PROFILE_ERROR,
    KILL_USER_PROFILE,
    KILL_USER_PROFILE_ERROR,
    GET_USER_PROFILE,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_ERROR,
    SET_ID_USER,
    LOGIN,
    LOGOUT,
    LOGOUT_ERROR,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    GET_PENDING,
    GET_PENDING_ERROR,
    GET_PENDING_SUCCESS,
    GET_REDEEMED,
    GET_REDEEMED_ERROR,
    GET_REDEEMED_SUCCESS,
    POST_COUPON_SUCCESS,
    POST_COUPON_REDEEM_SUCCESS,
    DELETE_COUPON_REDEEM,
  } = useUserTypes();

  const { GET_COUPONS_ERROR, GET_COUPONS_SUCCESS, GET_COUPONS } = useCouponTypes();

  const { useUserServices } = useServices();
  const {
    fetchUserProfile,
    login,
    fetchPendingCoupons,
    fetchRedeemedCoupons,
    fetchCoupons,
    postCoupon,
    postCouponRedeem,
  } = useUserServices();

  const actDeleteUserProfile = () => async (dispatch) => {
    try {
      dispatch({
        type: KILL_USER_PROFILE,
      });
    } catch (e) {
      dispatch({
        type: KILL_USER_PROFILE_ERROR,
        error: e,
      });
    }
  };
  const actFetchUserProfile = (userId) => async (dispatch) => {
    try {
      dispatch({
        type: GET_USER_PROFILE,
      });
      const profile = await fetchUserProfile(userId);
      dispatch({
        type: GET_USER_PROFILE_SUCCESS,
        payload: profile.data[userId],
      });
    } catch (e) {
      dispatch({
        type: GET_USER_PROFILE_ERROR,
        error: e,
      });
    }
  };

  const actSaveUserProfile = (data) => async (dispatch) => {
    try {
      dispatch({
        type: SAVE_USER_PROFILE,
      });

      dispatch({
        type: SAVE_USER_PROFILE_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: SAVE_USER_PROFILE_ERROR,
        error: e,
      });
    }
  };

  const actLogin = (data, callback) => async (dispatch) => {
    try {
      dispatch({
        type: LOGIN,
      });
      const user = await login(data);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: parseInt(user.data.id_user),
      });
      if (callback) {
        callback(user.data);
      }
    } catch (e) {
      dispatch({
        type: LOGIN_ERROR,
        error: e,
      });
    }
  };
  const actLogOut = (callback) => async (dispatch) => {
    try {
      dispatch({
        type: LOGOUT,
      });
      if (callback) {
        callback();
      }
    } catch (e) {
      dispatch({
        type: LOGOUT_ERROR,
        error: e,
      });
    }
  };
  const actFetchPendingCoupons = (userID) => async (dispatch) => {
    try {
      dispatch({
        type: GET_PENDING,
      });
      const data = await fetchPendingCoupons(userID);
      const coupons = Object.values(data.data.assignments).filter(
        (item) => item.status !== "expired"
      );
      dispatch({
        type: GET_PENDING_SUCCESS,
        payload: coupons,
      });
    } catch (e) {
      dispatch({
        type: GET_PENDING_ERROR,
        error: e,
      });
    }
  };
  const actFetchRedeemedCoupons = (userID) => async (dispatch) => {
    try {
      dispatch({
        type: GET_REDEEMED,
      });
      const data = await fetchRedeemedCoupons(userID);
      const coupons = Object.values(data.data.assignments).filter(
        (item) => item.status === "expired"
      );
      dispatch({
        type: GET_REDEEMED_SUCCESS,
        payload: coupons,
      });
    } catch (e) {
      dispatch({
        type: GET_REDEEMED_ERROR,
        error: e,
      });
    }
  };

  const actGetCoupons = (id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_COUPONS,
      });
      const res = await fetchCoupons(id);

      dispatch({
        type: GET_COUPONS_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: GET_COUPONS_ERROR,
        error: e,
      });
    }
  };

  const actPostNewCoupon = (data, onSuccess, onError) => async (dispatch) => {
    try {
      const { id_user } = data;
      const coupon = await postCoupon(data);
      dispatch({
        type: POST_COUPON_SUCCESS,
        payload: coupon.data,
      });
      dispatch({
        type: SET_ID_USER,
        payload: id_user,
      });
      onSuccess && onSuccess(coupon.data);
    } catch (e) {
      onError && onError(e);
    }
  };

  const actPostCouponRedeem = (data, onSuccess, onError) => async (dispatch) => {
    try {
      const { data: coupon } = await postCouponRedeem(data);
      dispatch({
        type: POST_COUPON_REDEEM_SUCCESS,
        payload: coupon,
      });
      onSuccess && onSuccess(coupon);
    } catch (e) {
      onError && onError(e);
    }
  };

  const actDeleteCouponRedeem = (onSuccess, onError) => (dispatch) => {
    try {
      dispatch({
        type: DELETE_COUPON_REDEEM,
      });
      onSuccess && onSuccess();
    } catch (e) {
      onError && onError(e);
    }
  };

  return {
    actDeleteUserProfile,
    actFetchUserProfile,
    actLogin,
    actLogOut,
    actFetchPendingCoupons,
    actFetchRedeemedCoupons,
    actGetCoupons,
    actPostNewCoupon,
    actPostCouponRedeem,
    actDeleteCouponRedeem,
    actSaveUserProfile,
  };
};

export default useUserActions;
