const useCouponInitialStates = () => {
  const couponInitialState = {
    data: {
      title: "",
      subtitle: "",
      description: "",
      brand: {
        tid: "",
        name: "",
        colors: {
          primary: "#000",
          secondary: "#8d8d8d",
          tertiary: "#6b2d2d",
        },
        brand_image: "",
        map_pin: "",
        register_image: "",
        agegate_image: "",
        agegate_image_desktop: "",
        textPrimaryAgegate: "",
        textSecondaryAgegate: "",
        facebook: "",
        instagram: "",
        twitter: "",
        front: "",
        terms: "",
        eventCategory: "",
        eventAction: "",
        agegate: 1,
        sso: 0,
        perfil: 0,
        indicatives: [],
        place: "",
        icon_map: "",
        age_gate_type: "",
        config_tapit: "",
      },
      tyc_pdf: "",
      pp_pdf: "",
      url_redirect: "",
      show_hide_qr: 0,
      show_hide_button_map: 0,
    },
  };

  const couponToShowInitialState = {
    data: {
      title: "",
      subtitle: "",
      description: "",
      brand: {
        tid: "",
        name: "",
        colors: {
          primary: "#000",
          secondary: "#8d8d8d",
          tertiary: "#6b2d2d",
        },
        brand_image: "",
        map_pin: "",
        register_image: "",
        agegate_image: "",
        agegate_image_desktop: "",
        textPrimaryAgegate: "",
        textSecondaryAgegate: "",
        facebook: "",
        instagram: "",
        twitter: "",
        front: "",
        terms: "",
        eventCategory: "",
        eventAction: "",
        agegate: 1,
        sso: 0,
        perfil: 0,
        indicatives: [],
        place: "",
        icon_map: "",
        age_gate_type: "",
        config_tapit: "",
      },
      tyc_pdf: "",
      pp_pdf: "",
      url_redirect: "",
      show_hide_qr: 0,
      show_hide_button_map: 0,
    },
    id: "",
    couponInfo: {
      id: "",
      status: "",
      name: "",
      last_name: "",
      code: "",
      dateEnd: "",
      redeemed_date: "",
      qr: "",
      titleCoupon: "",
      grocer_name: "",
      brand_id: "",
    },
  };

  const couponListInitialState = {
    status_code: "",
    message: "",
    assignments: [],
  };

  const couponIdInitialState = {
    id: "",
  };
  return {
    couponInitialState,
    couponToShowInitialState,
    couponListInitialState,
    couponIdInitialState,
  };
};

export default useCouponInitialStates;
