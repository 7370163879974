import { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import useControllers from "../../..";
import useModels from "../../../../models";
import useApi from "../../../../api";

const useAgeGateYear = () => {
  const { useSelectors } = useModels();
  const { useSelector, useAgeGateSelectors, useCouponSelectors, useUtmSelectors } = useSelectors();
  const { ageGateSelector } = useAgeGateSelectors();
  const { brandSelector } = useCouponSelectors();
  const { utmSelector } = useUtmSelectors();

  //const { couponParamsToShowSelector } = useCouponSelectors();

  const { useActions } = useApi();
  const { dispatch, useAgeGateActions, useMapActions } = useActions();
  const { actAgeGateCompleted, actAgeGateRememberData } = useAgeGateActions();
  const { actDirectlyFromMap } = useMapActions();
  const { useGeneralHooks } = useControllers();
  const { useDataLayers } = useGeneralHooks();
  const {
    gtmValidateAgeGateCompleted,
    gtmOnPremiseAgeGate,
    gtmOnPremiseAgeGateRemember,
  } = useDataLayers();

  const [year, setYear] = useState("");
  const [rememberData, setRememberData] = useState(false);
  const { completedAgeGate, rememberData: rememberDataChbx } = useSelector(ageGateSelector);
  const { name: brandNameCoupon } = useSelector(brandSelector);
  const { value: UTM } = useSelector(utmSelector);
  const { on_premise } = useSelector(brandSelector);

  const [error, setError] = useState("");
  const { push } = useHistory();
  const location = useLocation();
  const { brandName } = useParams();

  useEffect(() => {
    if (completedAgeGate) push(`/${brandName}/form${UTM && "?" + UTM}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedAgeGate]);

  const age = (dateString) => {
    const currentYear = new Date().getFullYear();
    return ~~(currentYear - parseInt(dateString));
  };

  const handleRememberData = () => {
    if (!rememberData) {
      if (on_premise === "1") {
        gtmOnPremiseAgeGateRemember({ interaction: "false" });
      }
    }
    setRememberData(!rememberData);
    dispatch(actAgeGateRememberData(!rememberData));
  };

  const handleYear = (data) => {
    setError("");
    setYear(data);
  };

  const submitYear = () => {
    const ageByYear = age(year);
    if (year.length > 4 && moment(year, ["YYYY"]) >= moment()) {
      setError("El año digitado no es valido");
    } else if (ageByYear >= 18) {
      if (on_premise === "1") {
        gtmOnPremiseAgeGate({ interaction: "false" });
      } else {
        gtmValidateAgeGateCompleted({
          eventLabel: "Yes",
          brandName: brandNameCoupon,
          interaction: "False",
        });
      }

      if (ageByYear > 18) {
        dispatch(actDirectlyFromMap(location.state ? location.state.fromMap : false));
        dispatch(actAgeGateCompleted(rememberData));
        if (location.state) {
          push(`/coupon${UTM && "?" + UTM}`);
        } else {
          push(`/${brandName}/form${UTM && "?" + UTM}`);
        }
      } else {
        dispatch(actDirectlyFromMap(location.state ? location.state.fromMap : false));
        push(`/${brandName}/age-gate/month${UTM && "?" + UTM}`, {
          year,
          rememberData,
          fromMap: location.state ? location.state.fromMap : false,
        });
      }
    } else {
      gtmValidateAgeGateCompleted({
        eventLabel: "No",
        brandName: brandNameCoupon,
        interaction: "False",
      });

      // window.location = "https://www.tapintoyourbeer.com";
      window.location = "https://www.vivamosresponsablemente.com/";
    }
  };

  return {
    error,
    handleYear,
    submitYear,
    year,
    handleRememberData,
    rememberDataChbx,
  };
};

export default useAgeGateYear;
