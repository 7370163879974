import useControllers from "../../../controllers";

const useAgeGateSelectors = () => {
  const { useGeneralHooks } = useControllers();
  const { useUtils } = useGeneralHooks();
  const { useCreateSelector } = useUtils();
  const { createSelector } = useCreateSelector();

  const ageGateSelector = createSelector(
    (state) => state.ageGate,
    (ageGate) => {
      return {
        ...ageGate,
      };
    }
  );

  return { ageGateSelector };
};

export default useAgeGateSelectors;
