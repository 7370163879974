import useControllers from "../../../controllers";
import useStrings from "../../../strings";
import useInitialStates from "../../initialStates";

const useCouponReducers = () => {
  const { useTypes } = useStrings();
  const { useCouponTypes } = useTypes();
  const {
    GE_COUPON_SUCCESS,
    GET_COUPONS,
    GET_COUPONS_SUCCESS,
    GET_COUPONS_ERROR,
    SAVE_ID_COUPON,
    GET_COUPON_TO_SHOW_SUCCESS,
    DELETE_COUPON_TO_SHOW,
  } = useCouponTypes();
  const { useGeneralHooks } = useControllers();
  const { useUtils } = useGeneralHooks();
  const { useCreateReducer } = useUtils();
  const { createReducer } = useCreateReducer();
  const { useCouponInitialStates } = useInitialStates();
  const {
    couponInitialState,
    couponListInitialState,
    couponIdInitialState,
    couponToShowInitialState,
  } = useCouponInitialStates();

  const coupon = createReducer(couponInitialState, {
    [GE_COUPON_SUCCESS](state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
  });

  const couponToShow = createReducer(couponToShowInitialState, {
    [GET_COUPON_TO_SHOW_SUCCESS](state, action) {
      return {
        ...state,
        data: action.payload.data,
        id: action.payload.id,
        couponInfo: action.payload.couponInfo,
      };
    },
    [DELETE_COUPON_TO_SHOW]() {
      return couponToShowInitialState;
    },
  });

  const couponList = createReducer(couponListInitialState, {
    [GET_COUPONS](state) {
      return {
        ...state,
        isFetching: true,
      };
    },
    [GET_COUPONS_SUCCESS](state, action) {
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };
    },
    [GET_COUPONS_ERROR](state) {
      return {
        ...state,
        isFetching: false,
      };
    },
  });

  const params = createReducer(couponIdInitialState, {
    [SAVE_ID_COUPON](state, action) {
      return {
        ...state,
        id: action.payload,
      };
    },
  });

  return { coupon, couponList, params, couponToShow };
};

export default useCouponReducers;
