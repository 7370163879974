import useProviders from "../../providers";

const useShopServices = () => {
  const { useShopProviders } = useProviders();
  const { get, getWithCoupons } = useShopProviders();

  const getShop = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await get(data));
      } catch (e) {
        reject(e);
      }
    });
  };
  const getWithCouponShop = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await getWithCoupons(data));
      } catch (e) {
        reject(e);
      }
    });
  };

  return {
    getShop,
    getWithCouponShop,
  };
};
export default useShopServices;
