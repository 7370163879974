import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useUserServices = () => {
  const { useUserProviders } = useProviders();
  const {
    postCouponProvider,
    getCouponsProvider,
    loginProvider,
    fetchPendingCouponsProvider,
    fetchUserProfileProvider,
    fetchRedeemedCouponsProvider,
    postCouponRedeemProvider,
  } = useUserProviders();

  const fetchUserProfile = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(fetchUserProfileProvider(data)));
      } catch (e) {
        reject(e);
      }
    });
  };

  const login = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(loginProvider(data)));
      } catch (e) {
        reject(e);
      }
    });
  };
  const fetchPendingCoupons = (userID) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(fetchPendingCouponsProvider(userID)));
      } catch (e) {
        reject(e);
      }
    });
  };
  const fetchRedeemedCoupons = (userID) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(fetchRedeemedCouponsProvider(userID)));
      } catch (e) {
        reject(e);
      }
    });
  };

  const fetchCoupons = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getCouponsProvider(id)));
      } catch (e) {
        reject(e);
      }
    });
  };
  const postCoupon = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(postCouponProvider(data)));
      } catch (e) {
        reject(e);
      }
    });
  };

  const postCouponRedeem = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(postCouponRedeemProvider(data)));
      } catch (e) {
        reject(e);
      }
    });
  };

  return {
    fetchUserProfile,
    login,
    fetchPendingCoupons,
    fetchRedeemedCoupons,
    fetchCoupons,
    postCoupon,
    postCouponRedeem,
  };
};

export default useUserServices;
