import useProfile from "./profile";
import useCoupons from "./coupons";

const useHome = () => {
  return {
    useProfile,
    useCoupons,
  };
};

export default useHome;
