import useControllers from "../../../controllers";
import useStrings from "../../../strings";
import useInitialStates from "../../initialStates";

const useOtpReducers = () => {
  const { useTypes } = useStrings();
  const { useOtpTypes, useUserTypes } = useTypes();
  const { OTP_SUCCESS } = useOtpTypes();
  const { LOGOUT } = useUserTypes();
  const { useOtpInitialStates } = useInitialStates();
  const { otpInitialState } = useOtpInitialStates();
  const { useGeneralHooks } = useControllers();
  const { useUtils } = useGeneralHooks();
  const { useCreateReducer } = useUtils();
  const { createReducer } = useCreateReducer();

  const otp = createReducer(otpInitialState, {
    [OTP_SUCCESS](state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    [LOGOUT]() {
      return otpInitialState;
    },
  });

  return { otp };
};

export default useOtpReducers;
