import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useModels from "../../../../models";
import useGeneralHooks from "../../../generalHooks";

const useTimerController = (brand) => {
  const { useSelectors } = useModels();
  const { useSelector, useOnPremiseSelectors, useUserSelectors } = useSelectors();
  const { onPremiseSelector } = useOnPremiseSelectors();
  const activeCoupon = useSelector(onPremiseSelector);

  const { brandName } = useParams();

  const { useUtils, useDataLayers } = useGeneralHooks();
  const { useInterval } = useUtils();

  const { userDataSelector } = useUserSelectors();
  const userInfo = useSelector(userDataSelector);

  const [progress, setProgress] = useState(0);
  const [isExpired, setIsExpired] = useState(false);
  const [isSpecificPromo, setIsSpecificPromo] = useState(false);

  const redeemDate = moment(activeCoupon.redeemDate);
  const expireDate = moment(activeCoupon.redeemDate).add(activeCoupon.minutesToExpire, "minutes");

  const { gtmOnPremiseCode, gtmOnPremiseCodeExpired, gtmOnPremiseTadaButton } = useDataLayers();

  const getTitle = (specificText) =>
    specificText
      ? "Muéstrale el contador al cajero/a para recibir tu 3x2 en Quilmes 473ml"
      : "Mostrale este mensaje al mozo y disfruta tu descuento";

  useInterval(() => {
    const currentDate = moment();
    setProgress(() => Math.round(((currentDate - redeemDate) / (expireDate - redeemDate)) * 100));
  }, 1000);

  useEffect(() => {
    setIsSpecificPromo(brandName?.toLowerCase?.().includes?.("quilmesrockoff"));
    if (activeCoupon.expired) setIsExpired(activeCoupon.expired);
    else setIsExpired(progress > 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, activeCoupon.expired]);

  useEffect(() => {
    if (activeCoupon.code != null) {
      if (activeCoupon.expired === true || isExpired === true) {
        gtmOnPremiseCodeExpired(
          activeCoupon.code,
          "True",
          "boton_expirado",
          "Expirado",
          userInfo.userId
        );
      } else {
        gtmOnPremiseCode(activeCoupon.code, "True", userInfo.province, "Validar", userInfo.userId);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpired, activeCoupon]);

  const handleGtmTadaButton = () => {
    gtmOnPremiseTadaButton("TaDa", "True", "boton_tada", "TaDá");
  };
  return {
    activeCoupon,
    expireDate,
    progress,
    isExpired,
    isSpecificPromo,
    getTitle,
    handleGtmTadaButton,
  };
};

export default useTimerController;
