import useStrings from "../../../strings";
import useServices from "../../services";

const useSplashSmsActions = () => {
  const { useTypes } = useStrings();
  const { useSplashSmsTypes } = useTypes();
  const { GET_URL_SMS_SUCCESS } = useSplashSmsTypes();
  const { useSplashSmsServices } = useServices();
  const { getUrlSmsSuccessService } = useSplashSmsServices();

  const actGetUrlSmsSuccess = ({ key }, onSuccess, onError) => async (dispatch) => {
    try {
      const res = await getUrlSmsSuccessService({ key });
      dispatch({
        type: GET_URL_SMS_SUCCESS,
      });
      onSuccess && onSuccess(res.data);
    } catch (e) {
      onError && onError(e);
    }
  };

  return {
    actGetUrlSmsSuccess,
  };
};

export default useSplashSmsActions;
