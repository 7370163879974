import { trackPromise } from 'react-promise-tracker'
import useProviders from '../../providers'

const useOnPremiseServices = () => {
  const { useOnPremiseProviders } = useProviders()
  const { checkStoreProvider } = useOnPremiseProviders()

  const checkStoreService = ({ idStore, idCoupon, idUser, code }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(checkStoreProvider({ idStore, idCoupon, idUser, code })))
      } catch (error) {
        reject(error)
      }
    })
  }

  return { checkStoreService }
}

export default useOnPremiseServices