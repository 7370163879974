import useInterceptor from "./interceptor";
import useStoreConfig from "./redux";

/*
  useConfig => HOOK
  - This hook exports everything related to config of application
*/
const useConfig = () => {
  return {
    useStoreConfig,
    useInterceptor,
  };
};

export default useConfig;
