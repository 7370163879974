import { toast } from "react-toastify";

const customToast = {
  success(msg, options = {}) {
    return toast.success(msg, {
      // Merge additionals options
      ...options,
      className: "rounded bg-success-500",
    });
  },
  error(msg, options = {}) {
    return toast.error(msg, {
      ...options,
      className: "rounded bg-error-500",
    });
  },
  info(msg, options = {}) {
    return toast.info(msg, {
      ...options,
      className: "rounded bg-black",
    });
  },
  warn(msg, options = {}) {
    return toast.warn(msg, {
      ...options,
      className: "rounded bg-warning-500",
    });
  },
};

export default customToast;
