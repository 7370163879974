import useControllers from "../../../controllers";
import useStrings from "../../../strings";
import useInitialStates from "../../initialStates";

const useMapReducers = () => {
  const { useTypes } = useStrings();
  const { useMapTypes } = useTypes();
  const { GET_SHOP_MAP, GET_SHOP_MAP_SUCCESS, GET_SHOP_MAP_ERROR, FLOW_FROM_MAP } = useMapTypes();
  const { useMapInitialStates } = useInitialStates();
  const { shopsInitialState, flowMapInitialState } = useMapInitialStates();
  const { useGeneralHooks } = useControllers();
  const { useUtils } = useGeneralHooks();
  const { useCreateReducer } = useUtils();
  const { createReducer } = useCreateReducer();

  const shops = createReducer(shopsInitialState, {
    [GET_SHOP_MAP](state) {
      return {
        ...state,
        isFetching: true,
      };
    },
    [GET_SHOP_MAP_SUCCESS](state, action) {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
      };
    },
    [GET_SHOP_MAP_ERROR](state) {
      return {
        ...state,
        isFetching: false,
      };
    },
  });

  const flowMap = createReducer(flowMapInitialState, {
    [FLOW_FROM_MAP](state, action) {
      return {
        ...state,
        isFromMap: action.payload,
      };
    },
  });

  return { shops, flowMap };
};

export default useMapReducers;
