import { useState } from "react";

const useToggleCoupons = () => {
  const [toggleCoupons, setToggleCoupons] = useState("pending");
  const [pselected, setPselected] = useState(true);
  const [rselected, setRselected] = useState(false);
  const handleTogglePending = () => {
    setPselected(true);
    setRselected(false);
    setToggleCoupons("pending");
  };
  const handleToggleRedeemed = () => {
    setPselected(false);
    setRselected(true);
    setToggleCoupons("redeemed");
  };
  return {
    pselected,
    rselected,
    toggleCoupons,
    handleTogglePending,
    handleToggleRedeemed,
  };
};

export default useToggleCoupons;
