const useSessionInitialStates = () => {
  const sessionInitialState = {
    brand: "",
    process: "",
  };

  return { sessionInitialState };
};

export default useSessionInitialStates;
