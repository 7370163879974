import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import useControllers from "../..";
import useApi from "../../../api";
import useSelectors from "../../../models/selectors";

const useSplash = () => {
  const [showExpiredScreen, setShowExpiredScreen] = useState(false);
  const { useGeneralHooks } = useControllers();
  const { useUtils } = useGeneralHooks();
  const { useQueryUtm } = useUtils();
  const { getQueryUtm } = useQueryUtm();
  const { useActions } = useApi();
  const {
    dispatch,
    useSplashActions,
    useCouponActions,
    useSessionActions,
    useUserActions,
    useUtmActions,
  } = useActions();
  const { actLogOut, actDeleteUserProfile, actDeleteCouponRedeem } = useUserActions();

  const { actGetCouponById, actDeleteCouponToShow } = useCouponActions();
  const { actSetSessionBrand } = useSessionActions();
  const { actGetBrandId } = useSplashActions();
  const { actSetUtm } = useUtmActions();
  const { useSelector, useCouponSelectors } = useSelectors();
  const { brandSelector } = useCouponSelectors();
  const brandData = useSelector(brandSelector);
  const { brandName } = useParams();
  const { push } = useHistory();

  useEffect(() => {
    dispatch(actDeleteCouponToShow());
    dispatch(actDeleteCouponRedeem());
    dispatch(actSetUtm({ utm: getQueryUtm }));

    const onSuccessGetBrandId = (data) => {
      const { id } = data;
      if (id === "35") handleAddPixel();
      dispatch(actLogOut());
      dispatch(actDeleteUserProfile());
      dispatch(actGetCouponById({ id }, onSuccessGetCouponById));
    };
    dispatch(
      actGetBrandId({ brandName, UTM: getQueryUtm }, onSuccessGetBrandId, onErrorGetBrandId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandName]);

  const handleAddPixel = () => {
    const script = document.createElement("script");
    script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '988219438023691');
      fbq('track', 'PageView');
    `;
    const noscript = document.createElement("noscript");
    const image = document.createElement("img");
    image.height = "1";
    image.width = "1";
    image.style = "display:none";
    image.src = "https://www.facebook.com/tr?id=988219438023691&ev=PageView&noscript=1";
    noscript.appendChild(image);

    const scripttag = document.createElement("script");
    scripttag.innerHTML = "fbq('track', 'REGISTRO');";

    const body = document.querySelector("body");
    body.appendChild(script);
    body.appendChild(noscript);
    body.appendChild(scripttag);
  };

  const onSuccessGetCouponById = (data) => {
    const { brand } = data;
    const { agegate, age_gate_type } = brand;
    dispatch(actSetSessionBrand({ brand: brandName }));

    const goToAgeGate = () => {
      if (agegate) {
        switch (age_gate_type) {
          case "yes_no":
            push(`/${brandName}/age-gate/yes-no${getQueryUtm && "?" + getQueryUtm}`);
            break;
          case "dd_mm_yyyy":
            push(`/${brandName}/age-gate/year${getQueryUtm && "?" + getQueryUtm}`);
            break;

          default:
            push(`/${brandName}/age-gate/yes-no${getQueryUtm && "?" + getQueryUtm}`);
            break;
        }
      } else {
        push(`/${brandName}/form${getQueryUtm && "?" + getQueryUtm}`);
      }
    };

    if (brand.expired_coupon === 0) {
      goToAgeGate();
      setShowExpiredScreen(false);
    } else {
      setShowExpiredScreen(true);
    }
  };
  const onErrorGetBrandId = () => {
    push("/");
  };

  return {
    showExpiredScreen,
    expiredImage: brandData.campaign_completion_image,
    expiredText: brandData.campaign_completion_text,
  };
};

export default useSplash;
