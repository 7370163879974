import { lazy } from "react";

// Layouts
const CustomLayout = lazy(() => import("./Custom"));
const FullLayout = lazy(() => import("./Full"));
const SimpleLayout = lazy(() => import("./Simple"));
const SimpleHeaderLayout = lazy(() => import("./SimpleHeader"));

const useLayouts = () => {
  return {
    CustomLayout,
    FullLayout,
    SimpleLayout,
    SimpleHeaderLayout,
  };
};

export default useLayouts;
