import useControllers from "../../../controllers";

const useGeneralSelectors = () => {
  const { useGeneralHooks } = useControllers();
  const { useUtils } = useGeneralHooks();
  const { useCreateSelector } = useUtils();
  const { createSelector } = useCreateSelector();

  const generalSelector = createSelector(
    (state) => state.general,
    (data) => data
  );

  const provincesSelector = createSelector(
    (state) => state.general,
    (general) => general.provinces
  )

  return { generalSelector, provincesSelector };
};

export default useGeneralSelectors;
