import { useDispatch } from "react-redux";
import useAgeGateActions from "./ageGate";
import useCouponActions from "./coupon";
import useSplashActions from "./splash";
import useMapActions from "./map";
import useOtpActions from "./otp";
import useShopActions from "./shop";
import useUserActions from "./user";
import useSessionActions from "./session";
import useUtmActions from "./utm";
import useSplashSmsActions from "./splashSms";
import useOnPremiseActions from "./onPremise";
import useGeneralActions from "./general";
/*
useActions => HOOK
- This hook exports other all actions
- rules:
1. The useDispatch is only imported here and exported in useActions
2. Action hooks must be called like this: prefix *use* + Name related to reducer + Suffix *Actions* (in prural)
- Example: useUserActions
3. Each action has 1 or more actions that must be named like this: prefix *act* + Name of action
- Example: actCreateUser
*/
const useActions = () => {
  const dispatch = useDispatch();
  return {
    dispatch,
    useAgeGateActions,
    useCouponActions,
    useMapActions,
    useOtpActions,
    useShopActions,
    useUserActions,
    useSplashActions,
    useSessionActions,
    useUtmActions,
    useSplashSmsActions,
    useOnPremiseActions,
    useGeneralActions,
  };
};

export default useActions;
