//Packages
import { useEffect } from "react";
import axios from "axios";

//Hooks
import useViews from "../../views";

const useInterceptor = (store) => {
  const { useComponents } = useViews();
  const { customToast, Toast } = useComponents();

  const handleResponseSuccess = (response) => {
    return response;
  };

  const handleResponseError = (error) => {
    const callToast = (text = "", listOfErrors) => {
      customToast.error(<Toast text={text} listOfErrors={listOfErrors} state={"error"} />, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    };

    switch (error.response.status) {
      case 400:
        callToast(error.response.data.message);
        break;
      case 401:
        localStorage.clear();
        window.location.reload();
        break;
      case 403:
        callToast(error.response.data.message);
        break;
      case 404:
        callToast(error.response.data.message);
        break;
      case 422:
        callToast(error.response.data.message);
        break;
      case 500:
        callToast(error.response.data.message);
        break;
      default:
        callToast(error.response.data.message);
        break;
      // not handler error
    }

    return Promise.reject(error);
  };

  const handleRequest = (request) => {
    const state = store.getState();

    request.headers["token"] = state.otp.data.token;
    request.headers.authorization = `Bearer ${state.otp.data.token}`;
    request.headers["Content-Type"] = "application/json";
    request.headers["accept"] = "application/json";
    return request;
  };

  useEffect(
    () => {
      axios.defaults.baseURL = process.env.REACT_APP_API_URL;
      axios.interceptors.request.use(handleRequest);
      axios.interceptors.response.use(handleResponseSuccess, handleResponseError);
    },
    // eslint-disable-next-line
    []
  );
};

export default useInterceptor;
