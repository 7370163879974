import { useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import useModels from "../../../../../models";
import useApi from "../../../../../api";

const useGenerateCoupon = () => {
  const { useSelectors } = useModels();
  const {
    useSelector,
    useUserSelectors,
    useCouponSelectors,
    useSessionSelectors,
    useUtmSelectors,
  } = useSelectors();
  const { logInSelector } = useUserSelectors();
  const { sessionSelector } = useSessionSelectors();
  const { brandSelector, couponSelectorAux } = useCouponSelectors();
  const { utmSelector } = useUtmSelectors();
  const { useActions } = useApi();
  const { dispatch, useUserActions, useCouponActions } = useActions();
  const { actPostNewCoupon } = useUserActions();
  const { actDeleteCouponToShow } = useCouponActions();

  const [limitModal, setLimitModal] = useState(false);
  const [showLast, setShowLast] = useState(true);
  const [modalText, setModalText] = useState("");
  const [date, setDate] = useState("");
  const { value: UTM } = useSelector(utmSelector);
  const { push } = useHistory();
  const { token } = useSelector(logInSelector);
  const couponParams = useSelector(couponSelectorAux);
  const { brand } = useSelector(sessionSelector);
  const {
    brand: { sso },
  } = useSelector(brandSelector);
  const toggleLimitModal = () => setLimitModal(!limitModal);
  const submit = () => {
    // Change the coupon id with the correct id, the number is just for testing
    dispatch(actDeleteCouponToShow());
    const data = {
      id_user: token,
      id_coupon: couponParams.id,
      UTM,
    };
    dispatch(
      actPostNewCoupon(data, (res) => {
        switch (res.status_code) {
          case "002":
            setModalText(res.message);
            setShowLast(false);
            toggleLimitModal();
            break;
          case "003":
            setShowLast(true);
            setModalText(`Puedes generar un cupon cada ${res.hoursAvailableToAssign} hrs`);
            setDate(
              moment(parseInt(res.dateRedeemed) * 1000).format("DD/MM/YYYY [a] [las] hh:mm a")
            );
            toggleLimitModal();
            break;
          case "004":
            push(`/${brand}/success${UTM && "?" + UTM}`, {
              ...res,
              stateQR: "Nuevo",
            });
            break;

          default:
            setModalText(res.message);
            toggleLimitModal();
            break;
        }
      })
    );
  };
  return {
    submit,
    toggleLimitModal,
    limitModal,
    modalText,
    date,
    showLast,
    sso,
  };
};

export default useGenerateCoupon;
