import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { triggerBase64Download } from "react-base64-downloader";
import useControllers from "../..";
import useModels from "../../../models";

const useSuccess = () => {
  const { state } = useLocation();
  const { useSelectors } = useModels();
  const {
    useSelector,
    useCouponSelectors,
    useUserSelectors,
    useSessionSelectors,
    useUtmSelectors,
  } = useSelectors();
  const {
    brandSelector,
    couponSelectorAux,
    couponRedeemSelector,
    couponToShowSelector,
    couponParamsToShowSelector,
  } = useCouponSelectors();
  const { profileSelector, postCouponSelector } = useUserSelectors();
  const { sessionSelector } = useSessionSelectors();
  const { utmSelector } = useUtmSelectors();
  const { name: brandNameCoupon } = useSelector(brandSelector);

  const { useGeneralHooks } = useControllers();
  const { useDataLayers } = useGeneralHooks();
  const { gtmEndExperience, gtmCouponSuccessQr } = useDataLayers();

  const [couponParamsToShow, setCouponParamsToShow] = useState({});
  const [couponToShow, setCouponToShow] = useState({});
  const [isOnPremise, setIsOnPremise] = useState(false);
  const history = useHistory();
  const couponParams = useSelector(couponSelectorAux);
  const couponParamsFromPerfil = useSelector(couponParamsToShowSelector);
  const couponFromPerfil = useSelector(couponToShowSelector);
  const couponFromRedeem = useSelector(couponRedeemSelector);
  const { coupon } = useSelector(postCouponSelector);
  const user = useSelector(profileSelector);
  const { id: userId } = user;
  const { cdp } = couponParams;
  const { process } = useSelector(sessionSelector);
  const { value: UTM } = useSelector(utmSelector);

  useEffect(() => {
    couponParamsFromPerfil.id
      ? setCouponParamsToShow(couponParamsFromPerfil)
      : setCouponParamsToShow(couponParams);

    couponFromPerfil.titleCoupon ? setCouponToShow(couponFromPerfil) : setCouponToShow(coupon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponParamsFromPerfil.id, couponFromPerfil.titleCoupon]);

  useEffect(() => {
    setIsOnPremise(couponParamsToShow?.brand?.on_premise === "1");
  }, [couponParamsToShow]);

  const goToUserHome = () => {
    history.push(`/user/home${UTM && "?" + UTM}`);
  };

  const goToMap = () =>
    (window.location.href =
      "https://www.google.com/maps/d/u/1/viewer?mid=1SbuiaI7MwULqb_evYjh6XjMLPXu_LfU&ll=-34.27947100994453%2C-61.566807066991174&z=5"); // history.push(`/map/${couponParams.id}${UTM && "?" + UTM}`);

  const downloadQrImage = (code, qr) => {
    gtmEndExperience({ couponCode: code, brandName: couponParams.brand.name });
    triggerBase64Download(qr, "Codigo QR");
    couponParams.brand.perfil && goToUserHome();
  };

  const showOrHideQR = () => {
    return (
      // from register
      (couponParams.show_hide_qr && process === "register" && !couponParamsFromPerfil.id) ||
      // form perfil
      (couponParams.brand.perfil && couponParamsFromPerfil.id) ||
      // from perfil when is generated a new coupon after login
      (couponParams.brand.perfil && process === "login" && !couponParamsFromPerfil.id) ||
      // from sms url
      (couponFromRedeem.status === "assigned" && process === "")
    );
  };
  const showOrHideGoToHomeButton = () => couponParams.brand.perfil;
  const showOrHideGoToMapButton = () => couponParamsToShow.show_hide_button_map;

  useEffect(() => {
    cdp &&
      showOrHideQR() &&
      gtmCouponSuccessQr({
        status: state?.stateQR === "Nuevo" ? "New" : "Pending",
        brandName: brandNameCoupon,
        userID: userId,
        campaignDescription: cdp.campaign_description,
        qrCode: couponToShow.code || couponFromRedeem.coupon_code,
        mlVolumePerUnit: cdp.ml_volume_per_unit,
        totalUnits: cdp.total_units,
        freeUnits: cdp.free_of_units,
        pricePerUnit: cdp.price_per_unit,
        currency: cdp.currency,
        city: "",
        beesPoint: "",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cdp]);
  return {
    downloadQrImage,
    couponFromRedeem,
    goToUserHome,
    goToMap,
    showOrHideQR,
    showOrHideGoToHomeButton,
    showOrHideGoToMapButton,
    user,
    couponParamsToShow,
    couponToShow,
    isOnPremise,
  };
};

export default useSuccess;
