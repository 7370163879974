import { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import useControllers from "../../..";
import useModels from "../../../../models";
import useApi from "../../../../api";

const useAgeGateDay = () => {
  const { useSelectors } = useModels();
  const { useSelector, useAgeGateSelectors, useCouponSelectors, useUtmSelectors } = useSelectors();
  const { ageGateSelector } = useAgeGateSelectors();
  const { brandSelector } = useCouponSelectors();
  const { utmSelector } = useUtmSelectors();

  const { useActions } = useApi();
  const { dispatch, useAgeGateActions } = useActions();
  const { actAgeGateCompleted, actAgeGateRememberData } = useAgeGateActions();
  const { useGeneralHooks } = useControllers();
  const { useDataLayers } = useGeneralHooks();
  const { gtmValidateAgeGateDay } = useDataLayers();

  const [day, setDay] = useState("");
  const { completedAgeGate, rememberData: rememberDataChbx } = useSelector(ageGateSelector);
  const [rememberData, setRememberData] = useState(false);
  const { name: brandNameCoupon } = useSelector(brandSelector);
  const { value: UTM } = useSelector(utmSelector);

  const [error, setError] = useState("");
  const { push } = useHistory();
  const location = useLocation();
  const { brandName } = useParams();

  useEffect(() => {
    if (location.state && location.state.rememberData) {
      setRememberData(location.state.rememberData);
    }
  }, [location]);
  useEffect(() => {
    if (completedAgeGate) push(`/${brandName}/form${UTM && "?" + UTM}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedAgeGate]);

  const handleRememberData = () => {
    setRememberData(!rememberData);
    dispatch(actAgeGateRememberData(!rememberData));
  };

  const handleDay = (data) => {
    setError("");
    setDay(data);
  };

  const age = (dateString) => {
    const birthday = +new Date(moment(dateString));
    return ~~((Date.now() - birthday) / 31557600000);
  };

  const submitDay = () => {
    if (day > 31) {
      return setError("El dia digitado no es valido");
    } else if (
      day.length === 2 &&
      brandName &&
      location.state &&
      location.state.year &&
      location.state.month
    ) {
      const legalAge = age(`${location.state.year}-${location.state.month}-${day}`);
      if (legalAge >= 18) {
        gtmValidateAgeGateDay({ day, brandName: brandNameCoupon });
        dispatch(actAgeGateCompleted(rememberData));
        if (location.state.fromMap) {
          push(`/coupon${UTM && "?" + UTM}`);
        } else {
          push(`/${brandName}/form${UTM && "?" + UTM}`);
        }
      } else {
        gtmValidateAgeGateDay({ day, brandName: brandNameCoupon });
        window.location = "https://www.vivamosresponsablemente.com/";
      }
    }
  };
  return {
    error,
    handleDay,
    submitDay,
    day,
    rememberData,
    handleRememberData,
    rememberDataChbx,
  };
};

export default useAgeGateDay;
