import useControllers from "../../../controllers";
import useStrings from "../../../strings";
import useInitialStates from "../../initialStates";

const useAgeGateReducers = () => {
  const { useAgeGateInitialStates } = useInitialStates();
  const { ageGateInititalState } = useAgeGateInitialStates();
  const { useTypes } = useStrings();
  const { useAgeGateTypes } = useTypes();
  const { AGE_GATE_COMPLETED, AGE_GATE_REMEMBER_DATA } = useAgeGateTypes();
  const { useGeneralHooks } = useControllers();
  const { useUtils } = useGeneralHooks();
  const { useCreateReducer } = useUtils();
  const { createReducer } = useCreateReducer();

  const ageGate = createReducer(ageGateInititalState, {
    [AGE_GATE_COMPLETED](state, action) {
      return {
        ...state,
        completedAgeGate: action.payload,
      };
    },
    [AGE_GATE_REMEMBER_DATA](state, action) {
      return {
        ...state,
        rememberData: action.payload,
      };
    },
  });
  return { ageGate };
};

export default useAgeGateReducers;
