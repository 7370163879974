import useStrings from "../../../strings";

const useSessionActions = () => {
  const { useTypes } = useStrings();
  const { useSessionTypes } = useTypes();
  const { SET_SESSION_PROCESS, SET_SESSION_BRAND } = useSessionTypes();

  const actSetSessionBrand = ({ brand }) => (dispatch) => {
    dispatch({
      type: SET_SESSION_BRAND,
      payload: brand,
    });
  };

  const actSetSessionProcess = ({ process }) => (dispatch) => {
    dispatch({
      type: SET_SESSION_PROCESS,
      payload: process,
    });
  };

  return { actSetSessionBrand, actSetSessionProcess };
};

export default useSessionActions;
