import React from "react";
import PropTypes from "prop-types";
const Toast = ({ text, title, listOfErrors }) => {
  return (
    <div>
      <div className={"flex flex-col justify-center text-center "}>
        {title !== "" && <div>{title}</div>}
        <div>{text}</div>
        {listOfErrors.length > 0 && listOfErrors.map((m, key) => <div key={key}>• {m}</div>)}
      </div>
    </div>
  );
};

Toast.defaultProps = {
  text: "",
  listOfErrors: [],
  hasOptions: false,
  action: () => {},
  title: "",
  hasImage: false,
  closeAction: () => {},
  state: "",
};

Toast.propTypes = {
  text: PropTypes.string,
  hasOptions: PropTypes.bool,
  listOfErrors: PropTypes.array,
  title: PropTypes.string,
  hasImage: PropTypes.bool,
  action: PropTypes.func,
  closeAction: PropTypes.func,
  state: PropTypes.string,
};

export default Toast;
