import { lazy } from "react";
import customToast from "./CustomToast";
import SpinnerLoadingIndicator from "./SpinnerLoading";
import Toast from "./Toast";

const ComponentExample = lazy(() => import("./ComponentExample"));
const ButtonNav = lazy(() => import("./ButtonNav"));
const Button = lazy(() => import("./Buttons"));
const Card = lazy(() => import("./Card"));
const CardCoupon = lazy(() => import("./CardCoupon"));
const CardNavbar = lazy(() => import("./CardNavbar"));
const Checkbox = lazy(() => import("./Checkbox"));
const CodeVerify = lazy(() => import("./CodeVerify"));
const Footer = lazy(() => import("./Footer"));
const Header = lazy(() => import("./Header"));
const Input = lazy(() => import("./Input"));
const Modal = lazy(() => import("./Modal"));
const Radio = lazy(() => import("./Radio"));
const ScrollToTop = lazy(() => import("./ScrollToTop"));
const CustomSelect = lazy(() => import("./Select"));
const Typography = lazy(() => import("./Typography"));

const useComponents = () => {
  return {
    ComponentExample,
    ButtonNav,
    Button,
    Card,
    CardCoupon,
    CardNavbar,
    Checkbox,
    CodeVerify,
    customToast,
    Footer,
    Header,
    Input,
    Modal,
    Radio,
    ScrollToTop,
    CustomSelect,
    Toast,
    Typography,
    SpinnerLoadingIndicator,
  };
};

export default useComponents;
