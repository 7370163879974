import useModels from "../../../models";

const useBrand = () => {
  const { useSelectors } = useModels();
  const { useSelector, useCouponSelectors } = useSelectors();
  const { colorSelector } = useCouponSelectors();
  const brand = useSelector(colorSelector);
  return {
    ...brand,
  };
};

export default useBrand;
