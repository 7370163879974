import { useEffect, useState } from "react";
import { config } from "./config";
import { useHistory } from "react-router-dom";
import useApi from "../../../api";
import useModels from "../../../models";

const useSso = () => {
  const { useActions, useServices } = useApi();
  const { useSsoServices } = useServices();
  const { loginSso } = useSsoServices();
  const { dispatch, useOtpActions, useUserActions } = useActions();
  const { actSaveToken } = useOtpActions();
  const { postNewCoupon } = useUserActions();
  const { useSelectors } = useModels();
  const { useSelector, useUtmSelectors, useSessionSelectors } = useSelectors();
  const { utmSelector } = useUtmSelectors();
  const { sessionSelector } = useSessionSelectors();
  const { brand } = useSelector(sessionSelector);
  const { value: UTM } = useSelector(utmSelector);

  const history = useHistory();
  let isOnAuthStateChange = false;
  const [isLoading, setLoading] = useState(false);
  const scriptSSO = document.createElement("script");
  const onVerifiedPhoneSuccess = ({ querySnapshot, document, userCredential }) => {
    querySnapshot.forEach(async (doc) => {
      const { phone } = doc.data();
      const {
        firstName,
        lastName,
        email,
        gender,
        birthDate: { seconds },
        identity,
      } = document;
      const userData = {
        firstName,
        lastName,
        email,
        phone,
        gender,
        birthDate: seconds,
        identity,
        userCredential,
      };

      const user = await loginSso(userData);
      if (user.data.status_code === "001") {
        setLoading(false);
        dispatch(actSaveToken({ token: user.data.token }, user.data.userId));
        dispatch(
          postNewCoupon(
            {
              id_user: parseInt(user.data.userId),
              id_coupon: 17,
              UTM,
            },
            (res) => {
              history.push(`/${brand}/success${UTM && "?" + UTM}`, {
                ...res,
                stateQR: "Nuevo",
              });
            }
          )
        );
      } else {
        // Usuario existente en cuponera
        setLoading(false);
        dispatch(
          actSaveToken({ token: user.data.token }, user.data.userId, () => {
            history.push(`/user/home${UTM && "?" + UTM}`);
          })
        );
      }
    });
  };

  const onUserAccountTap = ({ doc, userCredential }) => {
    const data = doc.data();
    // const getIdTokenResult = userCredential.getIdTokenResult();
    // const getIdToken = userCredential.getIdToken();

    if (data["phoneVerified"] === true) {
      window.ssoApp.firestore
        .collection("verifiedPhones")
        .where("uid", "==", userCredential.uid)
        .get()
        .then((querySnapshot) =>
          onVerifiedPhoneSuccess({ querySnapshot, document: data, userCredential })
        );
    } else {
      setLoading(false);
    }
  };

  const onAuthStateChange = (userCredential) => {
    if (userCredential) {
      setLoading(true);
      window.ssoApp.firestore
        .collection("user_account_tap")
        .doc(userCredential.uid)
        .onSnapshot((doc) => onUserAccountTap({ doc, userCredential }));
    } else {
      setLoading(false);
    }
  };
  const onLoadSso = () => {
    setInterval(() => {
      if (window.ssoApp && !isOnAuthStateChange) {
        isOnAuthStateChange = true;
        window.ssoApp.configApp(config);
        window.ssoApp.auth.onAuthStateChanged(onAuthStateChange);
      }
    }, 2000);
  };

  useEffect(() => {
    if (scriptSSO) {
      scriptSSO.src = process.env.REACT_APP_TAPIT_URL;
      scriptSSO.async = true;
      scriptSSO.onload = onLoadSso;
      document.body.appendChild(scriptSSO);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useSso;
