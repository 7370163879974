//Packages
import { createStore, applyMiddleware, compose } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

//Hooks
import useReducers from "../../models/reducers";

/*
  useStoreConfig => HOOK
  - This hook exports store and persistor of application
  - blacklist property of persistConfig have a all the name of the reducers in array,
    that you do not want the information to persist
*/
const useStoreConfig = () => {
  const rootReducers = useReducers();
  const initialState = {};
  let middleware = [];
  const persistConfig = {
    // Root?
    key: "root",
    storage: storage,
    blacklist: ["postCouponRedeem", "postCoupon"],
  };

  const persistReduce = persistReducer(persistConfig, rootReducers);
  if (process.env.NODE_ENV === "development") {
    const reduxInmmutableStateInvariant = require("redux-immutable-state-invariant").default();
    middleware = [...middleware, reduxInmmutableStateInvariant, thunk];
  } else {
    middleware = [...middleware, thunk];
  }

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  let store = createStore(
    persistReduce,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  let persistor = persistStore(store);

  return { store, persistor };
};

export default useStoreConfig;
