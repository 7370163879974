import useControllers from '../../../controllers'

const useOnPremiseSelectors = () => {
  const { useGeneralHooks } = useControllers()
  const { useUtils } = useGeneralHooks()
  const { useCreateSelector } = useUtils()
  const { createSelector } = useCreateSelector()

  const onPremiseSelector = createSelector(
    (state) => state.onPremise,
    (onPremise) => onPremise
  )

  return { onPremiseSelector }
}

export default useOnPremiseSelectors