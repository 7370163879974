import { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import useModels from "../../../../models";
import useApi from "../../../../api";
import useControllers from "../../..";

const useAgeGateYesNo = () => {
  const { useSelectors } = useModels();
  const { useSelector, useAgeGateSelectors, useCouponSelectors, useUtmSelectors } = useSelectors();
  const { ageGateSelector } = useAgeGateSelectors();
  const { brandSelector } = useCouponSelectors();
  const { utmSelector } = useUtmSelectors();

  const { useActions } = useApi();
  const { dispatch, useAgeGateActions, useCouponActions, useMapActions } = useActions();
  const { actAgeGateCompleted, actAgeGateRememberData } = useAgeGateActions();
  const { actSaveIdCoupon } = useCouponActions();
  const { actDirectlyFromMap } = useMapActions();
  const { brandName } = useParams();

  const { useGeneralHooks } = useControllers();
  const { useDataLayers } = useGeneralHooks();
  const { gtmValidateAgeGateCompleted } = useDataLayers();

  const [rememberData, setRememberData] = useState(false);
  const {
    brand: { sso },
    name: brandNameCoupon,
  } = useSelector(brandSelector);
  const { completedAgeGate, rememberData: rememberDataChbx } = useSelector(ageGateSelector);
  const { value: UTM } = useSelector(utmSelector);

  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(actSaveIdCoupon(id));
    }
    if (completedAgeGate) history.push(`/${brandName}/form${UTM && "?" + UTM}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, completedAgeGate]);

  const handleRememberData = () => {
    setRememberData(!rememberData);
    dispatch(actAgeGateRememberData(!rememberData));
  };

  const submitAgeCheck = (e) => {
    if (e.target.value === "Si") {
      dispatch(actDirectlyFromMap(location.state ? location.state.fromMap : false));
      dispatch(actAgeGateCompleted(rememberData));
      gtmValidateAgeGateCompleted({
        eventLabel: "Yes",
        brandName: brandNameCoupon,
        interaction: "False",
      });
      if (Boolean(sso)) {
        window.ssoApp.showApp("auth-login");
      } else {
        if (location.state) {
          history.push(`/coupon${UTM && "?" + UTM}`, {
            id,
          });
        } else {
          history.push(`/${brandName}/form${UTM && "?" + UTM}`, {
            id,
          });
        }
      }
    } else {
      gtmValidateAgeGateCompleted({
        eventLabel: "No",
        brandName: brandNameCoupon,
        interaction: "False",
      });
      window.location = "https://www.tapintoyourbeer.com";
    }
    console.log("test agegarte");
  };

  return {
    handleRememberData,
    submitAgeCheck,
    rememberData,
    rememberDataChbx,
  };
};

export default useAgeGateYesNo;
