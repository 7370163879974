import { combineReducers } from "redux";
import useAgeGateReducers from "./ageGate";
import useCouponReducers from "./coupon";
import useGeneralReducers from "./general";
import useMapReducers from "./map";
import useOtpReducers from "./otp";
import useUserReducers from "./user";
import useSessionReducers from "./session";
import useUtmReducers from "./utm";
import useOnPremiseReducer from "./onPremise";
// import useExampleReducer from "./exampleReducer";

/*
  useReducers => HOOK
  - This hook exports a combineCombineReducers  with all reducers
  -  All reducers was created with a createReducer function, 
    path of this function => src/controllers/generalHooks/utils/createReducer/index.js 
*/
const useReducers = () => {
  // const { example } = useExampleReducer();
  const { ageGate } = useAgeGateReducers();
  const { coupon, couponList, params, couponToShow } = useCouponReducers();
  const { general } = useGeneralReducers();
  const { flowMap, shops } = useMapReducers();
  const { otp } = useOtpReducers();
  const {
    login,
    pendingCoupons,
    postCoupon,
    redeemedCoupons,
    userProfile,
    postCouponRedeem,
    userSaveProfile,
  } = useUserReducers();
  const { session } = useSessionReducers();
  const { utm } = useUtmReducers();
  const { onPremise } = useOnPremiseReducer();

  return combineReducers({
    ageGate,
    coupon,
    couponList,
    couponToShow,
    params,
    general,
    flowMap,
    shops,
    otp,
    login,
    pendingCoupons,
    postCoupon,
    redeemedCoupons,
    userProfile,
    postCouponRedeem,
    session,
    utm,
    onPremise,
    userSaveProfile,
  });
};

export default useReducers;
