import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useSsoServices = () => {
  const { useSsoProviders } = useProviders();
  const { loginSsoProvider } = useSsoProviders();

  const loginSso = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(loginSsoProvider(data)));
      } catch (e) {
        reject(e);
      }
    });
  };

  return {
    loginSso,
  };
};

export default useSsoServices;
