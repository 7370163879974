import { useLocation } from "react-router-dom";
import useModels from "../../../models";

const useTerms = () => {
  const { useSelectors } = useModels();
  const { useSelector, useCouponSelectors, useGeneralSelectors } = useSelectors();
  const { couponSelector } = useCouponSelectors();
  const { generalSelector } = useGeneralSelectors();

  const location = useLocation();
  const coupon = useSelector(couponSelector);
  const general = useSelector(generalSelector);

  return {
    document: location.state?.isTC ? coupon.tyc_pdf : general.pp_pdf,
    title: location.state?.isTC ? "Términos y condiciones" : "Políticas de Privacidad",
  };
};

export default useTerms;
