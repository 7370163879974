import useCouponServices from "./coupon";
import useSplashServices from "./splash";
import useOtpServices from "./otp";
import useShopServices from "./shop";
import useUserServices from "./user";
import useSsoServices from "./sso";
import useSplashSmsServices from "./splashSms";
import useOnPremiseServices from "./onPremise";
import useGeneralServices from "./general";
/*
useServices => HOOK
- This hook exports all services
- rules:
1. Service hooks must be called like this: prefix *use* + Name related to service + Suffix *Services* (in prural)
- Example: useUserProviders
- Each service hook returns 1 or more individual services
  ...
  - Each service makes the request wrapped in a TRACKPROMISE (Library), this library keeps track of a promise:
  ⏳ "You only need to create a spinner component 
  and make use of the usePromiseTracker, this hook 
  will expose a boolean property that will let us 
  decide whether to show or hide the loading spinner."
  
  URL: https://www.npmjs.com/package/react-promise-tracker
  */
const useServices = () => {
  return {
    useCouponServices,
    useOtpServices,
    useShopServices,
    useUserServices,
    useSplashServices,
    useSsoServices,
    useSplashSmsServices,
    useOnPremiseServices,
    useGeneralServices,
  };
};

export default useServices;
