import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

const useFullLayout = () => {
  const [showHeader, setShowHeader] = useState(true);
  const location = useLocation();

  const urlIncludes = (path) => location.pathname.includes(path);

  useEffect(() => {
    const isInAgeGate = !(
      urlIncludes("form") ||
      urlIncludes("user") ||
      urlIncludes("map") ||
      urlIncludes("success") ||
      urlIncludes("on-premise/shop")
    );
    isInAgeGate ? setShowHeader(false) : setShowHeader(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return {
    showHeader,
  };
};

export default useFullLayout;
