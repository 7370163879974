import axios from "axios";

const useSplashSmsProviders = () => {
  const getUrlSmsSuccess = ({ key }) => {
    return axios({
      method: "POST",
      url: "api/shortener",
      data: { key },
    });
  };
  return {
    getUrlSmsSuccess,
  };
};

export default useSplashSmsProviders;
