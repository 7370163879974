import useHome from "./home";
import useLogin from "./login";
import useMenu from "./menu";
import useFormCoupon from "./profile";

const useUser = () => {
  return {
    useHome,
    useLogin,
    useMenu,
    useFormCoupon,
  };
};

export default useUser;
