import { useParams, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

import useControllers from "../..";
import useApi from "../../../api";

const useOnPremise = () => {
  const { push } = useHistory();

  const { useActions } = useApi();
  const {
    dispatch,
    useCouponActions,
    useUtmActions,
    useUserActions,
    useSessionActions,
    useSplashActions,
    useOnPremiseActions,
  } = useActions();
  const { actLogOut, actDeleteUserProfile } = useUserActions();
  const { actSetSessionBrand } = useSessionActions();
  const { actGetCouponById } = useCouponActions();
  const { actGetBrandId } = useSplashActions();
  const { actSetUtm } = useUtmActions();
  const { actCheckStore } = useOnPremiseActions();

  const { useGeneralHooks } = useControllers();
  const { useUtils, useDataLayers } = useGeneralHooks();
  const { useQueryUtm } = useUtils();
  const { getQueryUtm } = useQueryUtm();

  const [couponId, setCouponId] = useState(null);

  const { brandName, couponCode, idUser } = useParams();

  const { gtmOnPremiseCodeForm } = useDataLayers();

  useEffect(() => {
    dispatch(actSetUtm({ utm: getQueryUtm }));

    const onSuccessGetBrandId = (data) => {
      const { id } = data;
      setCouponId(id);
      dispatch(actLogOut());
      dispatch(actDeleteUserProfile());
      dispatch(actGetCouponById({ id }, onSuccessGetCouponById));
    };
    dispatch(
      actGetBrandId({ brandName, UTM: getQueryUtm }, onSuccessGetBrandId, onErrorGetBrandId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandName]);

  const onSuccessGetCouponById = () => {
    dispatch(actSetSessionBrand({ brand: brandName }));
  };

  const onErrorGetBrandId = () => {
    push("/");
  };

  const handleSuccessCheckStore = (clientId) => {
    push(`/${brandName}/on-premise/timer`);
    gtmOnPremiseCodeForm(clientId, "True", "boton_validar", "Validar", idUser);
  };

  const onSubmit = ({ clientId }) => {
    dispatch(
      actCheckStore(
        {
          idCoupon: couponId,
          idUser,
          idStore: clientId,
          couponCode,
        },
        handleSuccessCheckStore(clientId)
      )
    );
  };

  return {
    onSubmit,
  };
};

export default useOnPremise;
