import useAgeGateRoot from "./ageGateRoot";
import useAgeGateDay from "./day";
import useAgeGateMonth from "./month";
import useAgeGateYear from "./year";
import useAgeGateYesNo from "./yesNo";

const useAgeGate = () => {
  return {
    useAgeGateRoot,
    useAgeGateDay,
    useAgeGateMonth,
    useAgeGateYear,
    useAgeGateYesNo,
  };
};

export default useAgeGate;
