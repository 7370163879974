import useStrings from "../../../strings";
import useServices from "../../services";

const useCouponActions = () => {
  const { useTypes } = useStrings();
  const { useCouponTypes, useUserTypes } = useTypes();
  const {
    GE_COUPON_SUCCESS,
    SAVE_ID_COUPON,
    GET_COUPON_TO_SHOW_SUCCESS,
    DELETE_COUPON_TO_SHOW,
  } = useCouponTypes();
  const { LOGIN_SUCCESS } = useUserTypes();

  const { useCouponServices } = useServices();
  const { getCoupon, registerCoupon, validateCoupon } = useCouponServices();

  const actGetCouponById = ({ id }, onSuccess, onError) => async (dispatch) => {
    try {
      const res = await getCoupon({ id });
      dispatch({
        type: SAVE_ID_COUPON,
        payload: id,
      });
      const data = res.data;
      if (data[id]) {
        dispatch({
          type: GE_COUPON_SUCCESS,
          payload: data[id],
        });
      }
      onSuccess && onSuccess(data[id]);
    } catch (e) {
      onError && onError(e);
    }
  };

  const actGetCouponToShow = ({ id, couponInfo }, onSuccess, onError) => async (dispatch) => {
    try {
      const res = await getCoupon({ id });
      const data = res.data;
      if (data[id]) {
        dispatch({
          type: GET_COUPON_TO_SHOW_SUCCESS,
          payload: { data: data[id], id, couponInfo },
        });
      }
      onSuccess && onSuccess(data[id]);
    } catch (e) {
      onError && onError(e);
    }
  };

  const actDeleteCouponToShow = (onSuccess) => (dispatch) => {
    dispatch({
      type: DELETE_COUPON_TO_SHOW,
    });
    onSuccess && onSuccess();
  };

  const actRegister = (data, callback, onError) => async (dispatch) => {
    try {
      const res = await registerCoupon(data);
      const response = res.data;
      switch (response.status_code) {
        case "001":
          dispatch({
            type: LOGIN_SUCCESS,
            payload: parseInt(response.id_user),
          });
          break;

        case "019":
          dispatch({
            type: LOGIN_SUCCESS,
            payload: parseInt(response.id_user),
          });
          break;
        default:
          break;
      }
      callback(res.data);
    } catch (e) {
      onError && onError(e);
    }
  };

  const actSaveIdCoupon = (id) => (dispatch) => {
    dispatch({
      type: SAVE_ID_COUPON,
      payload: id,
    });
  };

  const actValidateCoupon = (dataId, onSuccess, onError) => async (dispatch) => {
    try {
      const res = await validateCoupon(dataId);

      const data = res.data;

      onSuccess && onSuccess(data);
    } catch (e) {
      onError && onError(e);
    }
  };

  return {
    actGetCouponById,
    actRegister,
    actSaveIdCoupon,
    actGetCouponToShow,
    actDeleteCouponToShow,
    actValidateCoupon,
  };
};

export default useCouponActions;
