import useReducers from "./reducers";
import useSelectors from "./selectors";
import useInitialStates from "./initialStates";

/*
  useModels => HOOK
  - This hook exports everything related to data and application management
*/
const useModels = () => {
  return {
    useSelectors,
    useReducers,
    useInitialStates
  };
};

export default useModels;
