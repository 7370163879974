//Packages
import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route as DefaultRoute, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";

// Styles
import "./App.css";
import "./styles/tailwind.css";
import "./styles/styles.css";
import { unregister as unregisterServiceWorker } from "./serviceWorker";

// Hooks
import useViews from "./views";
import useConfig from "./config";
import useControllers from "./controllers";

unregisterServiceWorker();

const App = () => {
  const { useStoreConfig, useInterceptor } = useConfig();
  const { store, persistor } = useStoreConfig();

  const { useGeneralHooks } = useControllers();
  const { useUtils } = useGeneralHooks();
  const { useGtm } = useUtils();
  const { isLocalhost } = useGtm();

  useInterceptor(store);

  // useViews Hook
  const { useScreens, useLayouts, useComponents } = useViews();
  // useScreens
  const {
    AgeGateYesNo,
    AgeGateDay,
    AgeGateMonth,
    AgeGateYear,
    Coupon,
    Form,
    Splash,
    SplashSms,
    Map,
    Otp,
    P404,
    Success,
    SuccessRefresh,
    Tyc,
    UserHome,
    UserLogin,
    UserProfile,
    OnPremiseRedeem,
    OnPremiseSuccess,
  } = useScreens();

  // useLayouts
  const { FullLayout } = useLayouts();

  // useComponents
  const { ScrollToTop, SpinnerLoadingIndicator } = useComponents();

  const Route = ({
    component: Component,
    layout: Layout = Component.Layout || FullLayout,
    ...rest
  }) => {
    return (
      <DefaultRoute
        {...rest}
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    );
  };

  useEffect(() => {
    if (!isLocalhost) {
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_API_GTMID,
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, [isLocalhost]);

  useEffect(() => {
    if (window) {
      //window.location.href = "https://tendero.promobavaria.com/"
    }
  }, []);
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={false}>
        <Router>
          <Suspense fallback={<SpinnerLoadingIndicator />}>
            <ScrollToTop>
              <Switch>
                <Route exact path="/:brandName" component={Splash} />
                <Route exact path="/:brandName/age-gate/yes-no" component={AgeGateYesNo} />
                <Route exact path="/:brandName/age-gate/year" component={AgeGateYear} />
                <Route exact path="/:brandName/age-gate/month" component={AgeGateMonth} />
                <Route exact path="/:brandName/age-gate/day" component={AgeGateDay} />
                <Route exact path="/:brandName/form" component={Form} />
                <Route exact path="/:brandName/otp" component={Otp} />
                <Route exact path="/:brandName/success" component={Success} />
                <Route
                  exact
                  path="/:brandName/on-premise/shop/:couponCode/:idUser"
                  component={OnPremiseRedeem}
                />
                <Route exact path="/:brandName/on-premise/timer" component={OnPremiseSuccess} />
                <Route exact path="/success/:brandNameSms/:key" component={SuccessRefresh} />
                <Route exact path="/s/:key" component={SplashSms} />
                <Route
                  exact
                  path="/terminos-y-condiciones"
                  component={() => {
                    window.location.href = "/terminos-y-condiciones.pdf";
                    return false;
                  }}
                />
                <Route exact path="/user/login" component={UserLogin} />
                <Route exact path="/user/home" component={UserHome} />
                <Route exact path="/user/profile" component={UserProfile} />
                <Route exact path="/map/:id" component={Map} />
                <Route exact path="/user/coupon" component={Coupon} />
                <Route exact path="/tyc" component={Tyc} />
                <Route component={P404} />
              </Switch>
            </ScrollToTop>
          </Suspense>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
