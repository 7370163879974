import axios from "axios";

const useOtpProviders = () => {
  const validate = (data) => {
    return axios({
      method: "post",
      url: `api/user/validate`,
      data,
    });
  };

  const send = (data) => {
    return axios({
      method: "post",
      url: `api/user/resend-sms-code-validation`,
      data,
    });
  };

  return {
    validate,
    send,
  };
};
export default useOtpProviders;
