const useUserInitialStates = () => {
  const userProfileInititalState = {
    isFetching: false,
    data: {
      name: "",
      last_name: "",
      email: "",
      phone: "",
      id: "",
    },
  };

  const userSaveProfileInititalState = {
    data: {
      name: "",
      email: "",
      phone: "",
      birtdayDate: "",
      province: "",
      userId: "",
    },
  };

  const loginInititalState = {
    isFetching: false,
    token: null,
  };

  const redeemedCouponsInititalState = { isFetching: false, coupons: [] };

  const pendingCouponsInititalState = { isFetching: false, coupons: [] };

  const postCouponInititalState = {
    code: "",
    dateBegin: "",
    dateEnd: "",
    message: "",
    name: "",
    last_name: "",
    qr: "",
    status_code: "",
    titleCoupon: "",
  };

  const couponRedeemInitialStatePost = {
    id: "",
    status: "",
    coupon_title: "",
    coupon_code: "",
    coupon_qr: "",
    coupon_description: "",
    observation: "",
    redeemed_date: "",
    due_date: "",
    grocer_name: "",
    name: "",
    last_name: "",
    titleCoupon: "",
    image: "",
    uid: "",
  };

  return {
    userProfileInititalState,
    loginInititalState,
    pendingCouponsInititalState,
    redeemedCouponsInititalState,
    postCouponInititalState,
    couponRedeemInitialStatePost,
    userSaveProfileInititalState,
  };
};

export default useUserInitialStates;
