import axios from "axios";

const useCouponProviders = () => {
  const get = ({ id }) => {
    return axios({
      method: "get",
      url: `api/data-coupon/${id}`,
    });
  };

  const register = (data) => {
    return axios({
      method: "post",
      url: `api/user/process`,
      data,
    });
  };

  const validate = (data) => {
    return axios({
      method: "post",
      url: `api/date-coupon-holder`,
      data,
    });
  };

  return {
    get,
    register,
    validate,
  };
};
export default useCouponProviders;
