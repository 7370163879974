import { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import useApi from "../../../../api";

const useAgeGateRoot = (submitRef, id) => {
  const { useActions } = useApi();
  const { dispatch, useCouponActions } = useActions();
  const { actGetCouponById } = useCouponActions();

  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const [timerMonth, setTimerMonth] = useState();
  const [timer, setTimer] = useState();
  const [month, setMonth] = useState("");
  const [error, setError] = useState("");

  const age = (dateString) => {
    const birthday = +new Date(dateString);
    return ~~((Date.now() - birthday) / 31557600000);
  };

  const onChangeMonth = (data) => {
    setMonth(data);
    setError("");
    if (timerMonth) {
      clearTimeout(timerMonth);
    }
    const timeout = setTimeout(() => {
      if (submitRef && !isNaN(data) && parseInt(data) < 13) {
        submitRef.click();
      } else {
        setError(`El mes ${data} no existe`);
      }
    }, 1000);
    setTimerMonth(timeout);
  };

  const onChangeYear = (data) => {
    if (timer) {
      clearTimeout(timer);
    }
    const timeout = setTimeout(() => {
      if (submitRef && !isNaN(data) && parseInt(month) < 13) {
        submitRef.click();
      }
    }, 2000);
    setTimer(timeout);
  };

  const submit = (data) => {
    let m = "";
    if (data.month.length > 1) {
      m = data.month;
    } else {
      m = `0${data.month}`;
    }

    if (data.year && data.year.length > 3) {
      const dateString = `${data.year}-${m}-01`;
      const ageData = age(dateString);
      if (ageData > 17) {
        if (location && location.pathname && location.pathname.includes("age-gate")) {
          history.push("/coupon", {
            id: params.id,
          });
        } else {
          history.push("/coupon", { fromHome: true, id: params.id });
        }
      } else {
        window.location = "https://www.tapintoyourbeer.com";
      }
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(
        actGetCouponById({ id }, (res) => {
          if (!Boolean(res?.brand?.agegate)) {
            if (Boolean(res?.brand?.sso)) {
              window.ssoApp.showApp("auth-login");
            } else {
              history.push(`/form/${id}`);
            }
          }
        })
      );
    }
    if ((location && location.pathname && location.pathname.includes("home"), params.id)) {
      dispatch(actGetCouponById({ id: params.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location, params, id, history]);

  return { onChangeMonth, onChangeYear, submit, error };
};

export default useAgeGateRoot;
