import useStrings from "../../../strings";
import useServices from "../../services";

const useOtpActions = () => {
  const { useTypes } = useStrings();
  const { useOtpTypes, useUserTypes } = useTypes();
  const { OTP_SUCCESS } = useOtpTypes();
  const { LOGIN, LOGIN_SUCCESS } = useUserTypes();

  const { useOtpServices } = useServices();
  const { validateOtp, sendOtp } = useOtpServices();

  const actValidateOtp = (data, onSuccess, onError) => async (dispatch) => {
    try {
      const res = await validateOtp(data);
      dispatch({
        type: OTP_SUCCESS,
        payload: res.data,
      });

      onSuccess && onSuccess(res.data);
    } catch (e) {
      onError && onError(e);
    }
  };

  const actSendCode = (data, onSuccess, onError) => async (dispatch) => {
    try {
      await sendOtp(data);
      onSuccess && onSuccess();
    } catch (e) {
      onError && onError(e);
    }
  };

  const actSaveToken = (data, userId, cb) => async (dispatch) => {
    dispatch({
      type: LOGIN,
    });
    dispatch({
      type: OTP_SUCCESS,
      payload: data,
    });
    dispatch({
      type: LOGIN_SUCCESS,
      payload: parseInt(userId),
    });
    cb && cb();
  };

  return {
    actValidateOtp,
    actSendCode,
    actSaveToken,
  };
};

export default useOtpActions;
