const useMapInitialStates = () => {
  const shopsInitialState = {
    data: [],
    isFetching: false,
  };

  const flowMapInitialState = { isFromMap: false };

  return { shopsInitialState, flowMapInitialState };
};

export default useMapInitialStates;
