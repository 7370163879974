import useControllers from "../../../controllers";
import useStrings from "../../../strings";
import useInitialStates from "../../initialStates";

const useUserReducers = () => {
  const { useTypes } = useStrings();
  const { useUserTypes } = useTypes();
  const {
    SAVE_USER_PROFILE,
    SAVE_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_ERROR,
    SET_ID_USER,
    KILL_USER_PROFILE,
    KILL_USER_PROFILE_ERROR,
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    LOGOUT_ERROR,
    GET_PENDING,
    GET_PENDING_SUCCESS,
    GET_PENDING_ERROR,
    GET_REDEEMED,
    GET_REDEEMED_SUCCESS,
    GET_REDEEMED_ERROR,
    POST_COUPON_SUCCESS,
    POST_COUPON_REDEEM_SUCCESS,
    DELETE_COUPON_REDEEM,
  } = useUserTypes();
  const { useUserInitialStates } = useInitialStates();
  const {
    userProfileInititalState,
    userSaveProfileInititalState,
    loginInititalState,
    pendingCouponsInititalState,
    redeemedCouponsInititalState,
    postCouponInititalState,
    couponRedeemInitialStatePost,
  } = useUserInitialStates();
  const { useGeneralHooks } = useControllers();
  const { useUtils } = useGeneralHooks();
  const { useCreateReducer } = useUtils();
  const { createReducer } = useCreateReducer();

  const userSaveProfile = createReducer(userSaveProfileInititalState, {
    [SAVE_USER_PROFILE](state) {
      return {
        ...state,
      };
    },
    [SAVE_USER_PROFILE_SUCCESS](state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
  });

  const userProfile = createReducer(userProfileInititalState, {
    [SAVE_USER_PROFILE](state, action) {
      return {
        ...state,
      };
    },
    [SAVE_USER_PROFILE_SUCCESS](state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    [GET_USER_PROFILE](state) {
      return {
        ...state,
        isFetching: true,
      };
    },
    [GET_USER_PROFILE_SUCCESS](state, action) {
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };
    },
    [SET_ID_USER](state, action) {
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          id: action.payload,
        },
      };
    },
    [GET_USER_PROFILE_ERROR](state, action) {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    },
    [KILL_USER_PROFILE](state) {
      return {
        ...state,
        data: {
          name: "",
          last_name: "",
          email: "",
          phone: "",
        },
      };
    },
    [KILL_USER_PROFILE_ERROR](state, action) {
      return {
        ...state,
        error: action.error,
      };
    },
  });

  const login = createReducer(loginInititalState, {
    [LOGIN](state) {
      return {
        ...state,
        isFetching: true,
      };
    },
    [LOGIN_SUCCESS](state, action) {
      return {
        ...state,
        isFetching: false,
        token: action.payload,
      };
    },
    [LOGIN_ERROR](state, action) {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    },
    [LOGOUT](state) {
      return loginInititalState;
    },
    [LOGOUT_ERROR](state, action) {
      return {
        ...state,
        error: action.error,
      };
    },
  });

  const pendingCoupons = createReducer(pendingCouponsInititalState, {
    [GET_PENDING](state) {
      return {
        ...state,
        isFetching: true,
      };
    },
    [GET_PENDING_SUCCESS](state, action) {
      return {
        ...state,
        isFetching: false,
        coupons: action.payload,
      };
    },
    [GET_PENDING_ERROR](state, action) {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    },
  });

  const redeemedCoupons = createReducer(redeemedCouponsInititalState, {
    [GET_REDEEMED](state) {
      return {
        ...state,
        isFetching: true,
      };
    },
    [GET_REDEEMED_SUCCESS](state, action) {
      return {
        ...state,
        isFetching: false,
        coupons: action.payload,
      };
    },
    [GET_REDEEMED_ERROR](state, action) {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    },
  });

  //FIXME: postCoupon
  const postCoupon = createReducer(postCouponInititalState, {
    [POST_COUPON_SUCCESS](state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  });

  const postCouponRedeem = createReducer(couponRedeemInitialStatePost, {
    [POST_COUPON_REDEEM_SUCCESS](state, action) {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    },
    [DELETE_COUPON_REDEEM](state) {
      return couponRedeemInitialStatePost;
    },
  });

  return {
    userProfile,
    login,
    pendingCoupons,
    redeemedCoupons,
    postCoupon,
    postCouponRedeem,
    userSaveProfile,
  };
};

export default useUserReducers;
