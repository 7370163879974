const useOnPremiseInitialState = () => {
  const onPremiseInitialState = {
    redeemDate: null,
    minutesToExpire: null,
    code: null
  }

  return { onPremiseInitialState }
}

export default useOnPremiseInitialState