const useCouponTypes = () => {
  const GE_COUPON_SUCCESS = "GE_COUPON_SUCCESS";

  const GET_COUPONS = "GET_COUPONS";

  const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS";

  const GET_COUPONS_ERROR = "GET_COUPONS_ERROR";

  const SAVE_ID_COUPON = "SAVE_ID_COUPON";

  const GET_COUPON_TO_SHOW_SUCCESS = "GET_COUPON_TO_SHOW_SUCCESS";

  const DELETE_COUPON_TO_SHOW = "DELETE_COUPON_TO_SHOW";

  return {
    GE_COUPON_SUCCESS,
    GET_COUPONS,
    GET_COUPONS_SUCCESS,
    GET_COUPONS_ERROR,
    SAVE_ID_COUPON,
    GET_COUPON_TO_SHOW_SUCCESS,
    DELETE_COUPON_TO_SHOW,
  };
};

export default useCouponTypes;
