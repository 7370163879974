import useControllers from "../../../controllers";

const useMapSelectors = () => {
  const { useGeneralHooks } = useControllers();
  const { useUtils } = useGeneralHooks();
  const { useCreateSelector } = useUtils();
  const { createSelector } = useCreateSelector();

  const flowFromMapSelector = createSelector(
    (state) => state.flowMap,
    (flowMap) => {
      return flowMap.isFromMap;
    }
  );

  const markerSelector = createSelector(
    (state) => state.shops,
    (shops) => {
      if (shops && Array.isArray(shops.data)) {
        return shops.data;
      }
      return [];
    }
  );

  return { flowFromMapSelector, markerSelector };
};

export default useMapSelectors;
