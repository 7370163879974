import axios from "axios";

const useSplashProviders = () => {
  const getBrandId = ({ brandName, UTM }) => {
    return axios({
      method: "POST",
      url: "api/data-coupon",
      data: { name: brandName, UTM },
    });
  };
  return { getBrandId };
};

export default useSplashProviders;
