import useControllers from "../../../controllers";

const useSessionSelectors = () => {
  const { useGeneralHooks } = useControllers();
  const { useUtils } = useGeneralHooks();
  const { useCreateSelector } = useUtils();
  const { createSelector } = useCreateSelector();

  const sessionSelector = createSelector(
    (state) => state.session,
    (session) => session
  );

  return { sessionSelector };
};

export default useSessionSelectors;
