import useAgeGateInitialStates from "./ageGate";
import useCouponInitialStates from "./coupon";
import useGenerealInitialStates from "./general";
import useMapInitialStates from "./map";
import useOtpInitialStates from "./otp";
import useSessionInitialStates from "./session";
import useUserInitialStates from "./user";
import useUtmIntialStates from "./utm";
import useOnPremiseInitialState from "./onPremise";
const useInitialStates = () => {
  return {
    useAgeGateInitialStates,
    useCouponInitialStates,
    useGenerealInitialStates,
    useMapInitialStates,
    useOtpInitialStates,
    useSessionInitialStates,
    useUserInitialStates,
    useUtmIntialStates,
    useOnPremiseInitialState
  };
};

export default useInitialStates;
