import useTypes from "./types";

/*
  useStrings => HOOK
  - This hook exports everything related to strings in this app (in this moment, only Types of application)
*/
const useStrings = () => {
  return {
    useTypes,
  };
};

export default useStrings;
