import { useHistory, useLocation } from "react-router-dom";
import useSelectors from "../../../models/selectors";
import useApi from "../../../api";

const useHeader = () => {
  const { useActions } = useApi();
  const { dispatch, useUserActions } = useActions();
  const { actLogOut, actDeleteUserProfile } = useUserActions();
  const { useSelector, useCouponSelectors, useOtpSelectors, useUtmSelectors } = useSelectors();
  const { couponSelector } = useCouponSelectors();
  const { otpSelector } = useOtpSelectors();
  const { utmSelector } = useUtmSelectors();

  const { value: UTM } = useSelector(utmSelector);
  const {
    data: { token },
  } = useSelector(otpSelector);
  const {
    brand: { perfil, brand_image },
  } = useSelector(couponSelector);
  const { push } = useHistory();
  const location = useLocation();

  const onLogOut = (sso) => {
    dispatch(
      actLogOut(() => {
        sso && window.ssoApp.logout();
        push(`/user/login${UTM && "?" + UTM}`, { sso });
      })
    );
    dispatch(actDeleteUserProfile());
  };

  const showButtonMenu = () =>
    perfil && token && (location.pathname.includes("user") || location.pathname.includes("map"));

  const redirectProfile = () => {
    push(`/user/profile${UTM && "?" + UTM}`);
  };
  const redirectHome = () => {
    push(`/user/home${UTM && "?" + UTM}`);
  };
  const showDefaultTextHeaderForm = () => !brand_image && location.pathname.includes("form");

  return {
    redirectProfile,
    redirectHome,
    showButtonMenu,
    showDefaultTextHeaderForm,
    onLogOut,
  };
};

export default useHeader;
