import useActions from "./actions";
import useProviders from "./providers";
import useServices from "./services";

/*
  useApi => HOOK
  - This hook exports other hooks related to all requests of the application
  - The order to integrate a new endpoint is this:
    1. Create a PROVIDER
    2. Create provider SERVICE
    3. Create the ACTION or actions to use the service
*/
const useApi = () => {
  return {
    useActions,
    useProviders,
    useServices,
  };
};

export default useApi;
