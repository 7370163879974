import useControllers from "../../../controllers";
import useStrings from "../../../strings";
import useInitialStates from "../../initialStates";

const useUtmReducers = () => {
  const { useTypes } = useStrings();
  const { useUtmTypes } = useTypes();
  const { SET_UTM } = useUtmTypes();
  const { useUtmIntialStates } = useInitialStates();
  const { utmIntialState } = useUtmIntialStates();
  const { useGeneralHooks } = useControllers();
  const { useUtils } = useGeneralHooks();
  const { useCreateReducer } = useUtils();
  const { createReducer } = useCreateReducer();

  const utm = createReducer(utmIntialState, {
    [SET_UTM](state, action) {
      return {
        ...state,
        value: action.payload,
      };
    },
  });

  return { utm };
};

export default useUtmReducers;
