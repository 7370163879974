import { useHistory, useLocation } from "react-router-dom";
import useControllers from "../..";
import useModels from "../../../models";
import useApi from "../../../api";

const useCoupon = () => {
  const { useSelectors } = useModels();
  const { useSelector, useMapSelectors, useUtmSelectors, useSessionSelectors } = useSelectors();
  const { flowFromMapSelector } = useMapSelectors();
  const { utmSelector } = useUtmSelectors();
  const { sessionSelector } = useSessionSelectors();
  const { useActions } = useApi();
  const { dispatch, useMapActions } = useActions();
  const { actDirectlyFromMap } = useMapActions();
  const { useScreensHooks } = useControllers();
  const { useBrand } = useScreensHooks();

  const history = useHistory();
  const flowMap = useSelector(flowFromMapSelector);
  const { value: UTM } = useSelector(utmSelector);
  const { brand } = useSelector(sessionSelector);
  const { banner } = useBrand();
  const location = useLocation();

  const goToRegister = () => {
    if (location?.state?.fromHome) {
      history.push(`/map/${location.state?.id}${UTM && "?" + UTM}`, { id: location.state?.id });
    } else {
      history.push(`/${brand}/form${UTM && "?" + UTM}`, { id: location.state?.id });
    }
  };

  const goToLogin = () => {
    if (location?.state?.fromHome) {
      history.push(`/map/${location.state?.id}${UTM && "?" + UTM}`, { id: location.state?.id });
    } else {
      history.push(`/user/login${UTM && "?" + UTM}`, { id: location.state?.id });
    }
  };
  const goToMap = () => {
    if (location?.state) {
      dispatch(actDirectlyFromMap(false));
      history.push(`/map/${location.state?.id}${UTM && "?" + UTM}`, {
        id: location.state?.id,
        flagMap: true,
      });
    }
  };

  return {
    goToRegister,
    banner,
    goToLogin,
    goToMap,
    flowMap,
  };
};

export default useCoupon;
