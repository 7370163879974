import { useLocation } from "react-router-dom";

const useQueryUtm = () => {
  const location = useLocation();

  const useQuery = () => new URLSearchParams(location.search);

  const getQueryUtm = useQuery().toString();
  return {
    getQueryUtm,
  };
};

export default useQueryUtm;
