const useMapTypes = () => {
  const GET_SHOP_MAP = "GET_SHOP_MAP";

  const GET_SHOP_MAP_SUCCESS = "GET_SHOP_MAP_SUCCESS";

  const GET_SHOP_MAP_ERROR = "GET_SHOP_MAP_ERROR";

  const FLOW_FROM_MAP = "FLOW_FROM_MAP";

  return { GET_SHOP_MAP, GET_SHOP_MAP_SUCCESS, GET_SHOP_MAP_ERROR, FLOW_FROM_MAP };
};

export default useMapTypes;
