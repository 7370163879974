const useUserTypes = () => {
  const SAVE_USER_PROFILE = "SAVEUSER_PROFILE";

  const SAVE_USER_PROFILE_SUCCESS = "SAVE_USER_PROFILE_SUCCESS";

  const SAVE_USER_PROFILE_ERROR = "SAVE_USER_PROFILE_ERROR";

  const GET_USER_PROFILE = "GET_USER_PROFILE";

  const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";

  const GET_USER_PROFILE_ERROR = "GET_USER_PROFILE_ERROR";

  const SET_ID_USER = "SET_ID_USER";

  const KILL_USER_PROFILE = "KILL_USER_PROFILE";

  const KILL_USER_PROFILE_ERROR = "KILL_USER_PROFILE_ERROR";

  const LOGIN = "LOGIN";

  const LOGIN_SUCCESS = "LOGIN_SUCCESS";

  const LOGIN_ERROR = "LOGIN_ERROR";

  const LOGOUT = "LOGOUT";

  const LOGOUT_ERROR = "LOGOUT_ERROR";

  const GET_PENDING = "GET_PENDING";

  const GET_PENDING_SUCCESS = "GET_PENDING_SUCCESS";

  const GET_PENDING_ERROR = "GET_PENDING_ERROR";

  const GET_REDEEMED = "GET_REDEEMED";

  const GET_REDEEMED_SUCCESS = "GET_REDEEMED_SUCCESS";

  const GET_REDEEMED_ERROR = "GET_REDEEMED_ERROR";

  const POST_COUPON_SUCCESS = "POST_COUPON_SUCCESS";

  const POST_COUPON_REDEEM_SUCCESS = "POST_COUPON_REDEEM_SUCCESS";

  const DELETE_COUPON_REDEEM = "DELETE_COUPON_REDEEM";

  return {
    GET_USER_PROFILE,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_ERROR,
    SET_ID_USER,
    KILL_USER_PROFILE,
    KILL_USER_PROFILE_ERROR,
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    LOGOUT_ERROR,
    GET_PENDING,
    GET_PENDING_SUCCESS,
    GET_PENDING_ERROR,
    GET_REDEEMED,
    GET_REDEEMED_SUCCESS,
    GET_REDEEMED_ERROR,
    POST_COUPON_SUCCESS,
    POST_COUPON_REDEEM_SUCCESS,
    DELETE_COUPON_REDEEM,
    SAVE_USER_PROFILE,
    SAVE_USER_PROFILE_SUCCESS,
    SAVE_USER_PROFILE_ERROR,
  };
};

export default useUserTypes;
