import { useEffect } from "react";
import useModels from "../../../../../models";
import useApi from "../../../../../api";

const useProfile = () => {
  const { useSelectors } = useModels();
  const { useSelector, useUserSelectors } = useSelectors();
  const { logInSelector } = useUserSelectors();
  const { useActions } = useApi();
  const { dispatch, useUserActions } = useActions();
  const { actFetchUserProfile } = useUserActions();

  const { token } = useSelector(logInSelector);
  useEffect(() => {
    dispatch(actFetchUserProfile(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token]);
};

export default useProfile;
