import useStrings from "../../../strings";

const useMapActions = () => {
  const { useTypes } = useStrings();
  const { useMapTypes } = useTypes();
  const { FLOW_FROM_MAP } = useMapTypes();

  const actDirectlyFromMap = (flag) => (dispatch) => {
    dispatch({
      type: FLOW_FROM_MAP,
      payload: flag,
    });
  };

  return {
    actDirectlyFromMap,
  };
};

export default useMapActions;
