import { useState, useEffect, useRef } from "react";
// import { useHistory, useLocation, useParams } from "react-router-dom";
import useControllers from "../..";
import useModels from "../../../models";
import useApi from "../../../api";

const useMap = () => {
  const { useSelectors } = useModels();
  const { useSelector, /*useUserSelectors*/ useMapSelectors } = useSelectors();
  // const { logInSelector } = useUserSelectors();
  const { markerSelector } = useMapSelectors();
  const { useActions } = useApi();
  const { dispatch, useShopActions } = useActions();
  const { actGetShops } = useShopActions();
  const { useScreensHooks } = useControllers();
  const { useBrand } = useScreensHooks();

  // const history = useHistory();
  // const { id } = useParams();
  // const location = useLocation();
  // const { token } = useSelector(logInSelector);
  const [modalHandler, setModalHandler] = useState(false);
  const [modalShown, setModalShown] = useState(false);
  const [coords, setCoords] = useState(null);
  const [geoPermission, setGeoPermission] = useState(false);
  const [activeStore, setActiveStore] = useState(null);
  const markers = useSelector(markerSelector);
  const params = useSelector((state) => state.params);
  const { map_pin } = useBrand();
  const auxMap = useRef();
  const auxMaps = useRef();
  const toggleEmptyStoreModal = () => {
    setModalHandler(!modalHandler);
  };

  const openDirection = () => {
    if (coords && window && activeStore) {
      window.location.href = `https://www.google.com/maps/dir/${coords.lat},${coords.lng}/${activeStore.lat},${activeStore.lng}`;
    }
  };

  const geoSuccess = (pos) => {
    setGeoPermission(true);
    let crd = pos.coords;
    setCoords({ lat: crd.latitude, lng: crd.longitude });
  };

  const geoErrors = () => {
    setGeoPermission(false);
  };

  const onMarker = (e) => {
    console.log({ e });
    setActiveStore(e);
    if (e.lat && e.lng) {
      const map = auxMap.current;
      map.setCenter({ lat: e.lat, lng: e.lng });
    }
  };

  useEffect(() => {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(geoSuccess, geoErrors);
    }
  }, [geoPermission]);

  useEffect(() => {
    if (!modalShown) {
      setModalShown(true);
      setModalHandler(!(markers.length > 0));
    }
    let marks = [];
    if (auxMaps.current && auxMap.current) {
      const map = auxMap.current;
      const maps = auxMaps.current;
      marks = markers.reduce((acc, cv) => {
        if (cv && cv.lat && cv.lng) {
          let m = new maps.Marker({
            position: {
              lat: cv.lat,
              lng: cv.lng,
            },
            id: cv.id,
            icon: map_pin,
          });
          if (m) {
            m.info = cv;
            m.setMap(map);
            m.addListener("click", (e) => {
              onMarker(m.info);
            });
            acc.push(m);
          }
        }
        return acc;
      }, []);
    }
    return () => {
      if (marks.length > 0) {
        marks.forEach((m) => {
          if (m) {
            m.setMap(null);
          }
        });
      }
    };
  }, [markers, map_pin, modalShown]);

  const handleApiLoaded = (map, maps) => {
    console.log(map, maps);

    if (map && coords) {
      auxMap.current = map;
      auxMaps.current = maps;
      map.setCenter(coords);
      map.setZoom(14);
      map.addListener("dragend", () => {
        const data = {
          center: {
            lat: map.getCenter().lat(),
            lng: map.getCenter().lng(),
          },
          country_code: "ar",
          east: map.getBounds().getNorthEast().lng(),
          north: map.getBounds().getNorthEast().lat(),
          south: map.getBounds().getSouthWest().lat(),
          west: map.getBounds().getSouthWest().lng(),
          zoom: 400,
        };
        dispatch(actGetShops(data, params.id));
      });

      const data = {
        center: {
          lat: map.getCenter().lat(),
          lng: map.getCenter().lng(),
        },
        country_code: "ar",
        east: map.getBounds().getNorthEast().lng(),
        north: map.getBounds().getNorthEast().lat(),
        south: map.getBounds().getSouthWest().lat(),
        west: map.getBounds().getSouthWest().lng(),
        zoom: 400,
      };
      dispatch(actGetShops(data, params.id));
    }
  };

  return {
    modalHandler,
    toggleEmptyStoreModal,
    openDirection,
    handleApiLoaded,
    coords,
    activeStore,
  };
};

export default useMap;
