import useStrings from "../../../strings";

const useUtmActions = () => {
  const { useTypes } = useStrings();
  const { useUtmTypes } = useTypes();
  const { SET_UTM } = useUtmTypes();

  const actSetUtm = ({ utm }, onSuccess, onError) => (dispatch) => {
    try {
      dispatch({
        type: SET_UTM,
        payload: utm,
      });
      onSuccess && onSuccess();
    } catch (e) {
      onError && onError(e);
    }
  };

  return { actSetUtm };
};

export default useUtmActions;
