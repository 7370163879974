import axios from "axios";

const useSsoProviders = () => {
  const loginSsoProvider = (data) => {
    return axios({
      method: "post",
      url: "/api/sso",
      data,
    });
  };
  return {
    loginSsoProvider,
  };
};

export default useSsoProviders;
