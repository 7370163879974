import useControllers from "../../../controllers";

const useOtpSelectors = () => {
  const { useGeneralHooks } = useControllers();
  const { useUtils } = useGeneralHooks();
  const { useCreateSelector } = useUtils();
  const { createSelector } = useCreateSelector();

  const otpSelector = createSelector(
    (state) => state.otp,
    (otp) => {
      return {
        ...otp,
      };
    }
  );

  return { otpSelector };
};

export default useOtpSelectors;
