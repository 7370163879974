import useControllers from "../../../controllers";

const useUserSelectors = () => {
  const { useGeneralHooks } = useControllers();
  const { useUtils } = useGeneralHooks();
  const { useCreateSelector } = useUtils();
  const { createSelector } = useCreateSelector();

  const profileSelector = createSelector(
    (state) => state.userProfile,
    (userProfile) => {
      return {
        ...userProfile.data,
      };
    }
  );

  const userDataSelector = createSelector(
    (state) => state.userSaveProfile,
    (userSaveProfile) => {
      return {
        ...userSaveProfile.data,
      };
    }
  );

  const logInSelector = createSelector(
    (state) => state.login,
    (login) => {
      return {
        ...login,
      };
    }
  );

  const postCouponSelector = createSelector(
    (state) => state.postCoupon,
    (coupon) => {
      return {
        coupon,
      };
    }
  );

  return { profileSelector, logInSelector, postCouponSelector, userDataSelector };
};

export default useUserSelectors;
