import useControllers from "../../../controllers";

const useUtmSelectors = () => {
  const { useGeneralHooks } = useControllers();
  const { useUtils } = useGeneralHooks();
  const { useCreateSelector } = useUtils();
  const { createSelector } = useCreateSelector();

  const utmSelector = createSelector(
    (state) => state.utm,
    (utm) => {
      return {
        ...utm,
      };
    }
  );

  return { utmSelector };
};

export default useUtmSelectors;
