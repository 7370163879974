import useComponentsHooks from "./componentHooks";
import useGeneralHooks from "./generalHooks";
import useLayoutHooks from "./layoutHooks";
import useScreensHooks from "./screenHooks";

/*
  useControllers => HOOK
  - This hook exports everything related to hooks of all components of application: Screens, Components, Layouts, generals, etc
  - Rules:
    1. Every hook name starts with lowercase and prefix *use*
    2. You can use: selectos, other hooks, actions, etc, 
    importing your top hooks and progressively destructuring until you get what you need:
    useViews(), useModels(), useControllers(), etc 😎
    3. Every Screen/Component/Layout have a your hook in folder with same name of that view
    4. In general Hooks folder we have all hooks general of application
*/
const useControllers = () => {
  return {
    useComponentsHooks,
    useGeneralHooks,
    useLayoutHooks,
    useScreensHooks,
  };
};

export default useControllers;
