const useGtm = () => {
  const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );

  const getGtmArgs = (
    event = null,
    eventCategory = null,
    eventAction = null,
    eventLabel = null,
    eventValue = null,
    eventBrand = null,
    UTM = null,
    info = {}
  ) => {
    return {
      dataLayer: {
        event,
        eventCategory,
        eventAction,
        eventLabel,
        eventValue,
        eventBrand,
        UTM,
        ...info,
      },
    };
  };

  const getNewGtmArgs = (
    event = null,
    event_category = null,
    event_action = null,
    event_label = null,
    UTM = null,
    info = {}
  ) => {
    return {
      dataLayer: {
        event,
        event_category,
        event_action,
        event_label,
        UTM,
        ...info,
      },
    };
  };

  const getOnPremiseGtmArgs = (
    event = null,
    event_category = null,
    event_action = null,
    event_label = null,
    info = {}
  ) => {
    return {
      dataLayer: {
        event,
        event_category,
        event_action,
        event_label,
        ...info,
      },
    };
  };

  return {
    isLocalhost,
    getGtmArgs,
    getNewGtmArgs,
    getOnPremiseGtmArgs,
  };
};

export default useGtm;
