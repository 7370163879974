import useStrings from "../../../strings";
import useServices from "../../services";

const useSplashActions = () => {
  const { useTypes } = useStrings();
  const { useSplashTypes } = useTypes();
  const { GET_BRAND_ID } = useSplashTypes();
  const { useSplashServices } = useServices();
  const { getBrandIdService } = useSplashServices();

  const actGetBrandId = ({ brandName, UTM }, onSuccess, onError) => async (dispatch) => {
    try {
      const res = await getBrandIdService({ brandName, UTM });
      dispatch({
        type: GET_BRAND_ID,
      });
      onSuccess && onSuccess(res.data);
    } catch (e) {
      onError && onError(e);
    }
  };

  return { actGetBrandId };
};

export default useSplashActions;
