import { lazy } from "react";

const ScreenExample = lazy(() => import("./ScreenExample"));
const AgeGate = lazy(() => import("./AgeGate"));
const AgeGateYesNo = lazy(() => import("./AgeGate/yesNo"));
const AgeGateDay = lazy(() => import("./AgeGate/day"));
const AgeGateMonth = lazy(() => import("./AgeGate/month"));
const AgeGateYear = lazy(() => import("./AgeGate/year"));
const Coupon = lazy(() => import("./Coupon"));
const Form = lazy(() => import("./Form"));
const Splash = lazy(() => import("./Splash"));
const SplashSms = lazy(() => import("./SplashSms"));
const Map = lazy(() => import("./Map"));
const Otp = lazy(() => import("./Otp"));
const P404 = lazy(() => import("./P404"));
const Success = lazy(() => import("./Success"));
const SuccessRefresh = lazy(() => import("./SuccessRefresh"));
const Tyc = lazy(() => import("./Tyc"));
const UserHome = lazy(() => import("./User/Home"));
const UserLogin = lazy(() => import("./User/Login"));
const UserProfile = lazy(() => import("./User/Profile"));
const OnPremiseRedeem = lazy(() => import('./OnPremise/Redeem'))
const OnPremiseSuccess = lazy(() => import('./OnPremise/Success'))

const useScreens = () => {
  // Screens

  return {
    ScreenExample,
    AgeGate,
    AgeGateYesNo,
    AgeGateDay,
    AgeGateMonth,
    AgeGateYear,
    Coupon,
    Form,
    Splash,
    SplashSms,
    Map,
    Otp,
    P404,
    Success,
    SuccessRefresh,
    Tyc,
    UserHome,
    UserLogin,
    UserProfile,
    OnPremiseRedeem,
    OnPremiseSuccess
  };
};

export default useScreens;
