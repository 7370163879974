import axios from 'axios'

const useOnPremiseProviders = () => {
  const checkStoreProvider = ({ idStore, idCoupon, idUser, code }) => {
    return axios({
      method: 'POST',
      url: 'api/user/check-store-code',
      data: {
        id_user: idUser,
        id_store: idStore,
        id_coupon: idCoupon,
        code
      }
    })
  }

  return { checkStoreProvider }
}

export default useOnPremiseProviders