import useControllers from "../../../controllers";
import useInitialStates from "../../initialStates";
import useStrings from "../../../strings";

const useGeneralReducers = () => {
  const { useGenerealInitialStates } = useInitialStates();
  const { generalInitialState } = useGenerealInitialStates();

  const { useGeneralHooks } = useControllers();
  const { useUtils } = useGeneralHooks();
  const { useCreateReducer } = useUtils();
  const { createReducer } = useCreateReducer();

  const { useTypes } = useStrings();
  const { useGeneralTypes } = useTypes();
  const { GET_PROVINCES } = useGeneralTypes();

  const general = createReducer(generalInitialState, {
    [GET_PROVINCES](state, action) {
      return {
        ...state,
        provinces: action.payload,
      };
    },
  });

  return { general };
};

export default useGeneralReducers;
