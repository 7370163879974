import { useState } from "react";

const useCodeVerify = (onChanges) => {
  const [value, setValue] = useState("");

  const handleChange = (newValue) => {
    onChanges(newValue);
    setValue(newValue);
  };
  return {
    value,
    handleChange,
  };
};

export default useCodeVerify;
