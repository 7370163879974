import useStrings from "../../../strings";

const useAgeGateActions = () => {
  const { useTypes } = useStrings();
  const { useAgeGateTypes } = useTypes();
  const { AGE_GATE_COMPLETED, AGE_GATE_REMEMBER_DATA } = useAgeGateTypes();

  const actAgeGateCompleted = (remember) => (dispatch) => {
    dispatch({
      type: AGE_GATE_COMPLETED,
      payload: remember,
    });
  };

  const actAgeGateRememberData = (remember) => (dispatch) => {
    dispatch({
      type: AGE_GATE_REMEMBER_DATA,
      payload: remember,
    });
  };

  return {
    actAgeGateCompleted,
    actAgeGateRememberData,
  };
};

export default useAgeGateActions;
