import axios from "axios";

const useGeneralProviders = () => {
  const fetchProvincesProvider = () => {
    return axios({
      method: "get",
      url: "/api/register/cities",
    });
  };

  return {
    fetchProvincesProvider,
  };
};

export default useGeneralProviders;
