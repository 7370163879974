import useGtm from "./gtm";
import useCreateReducer from "./createReducer";
import useCreateSelector from "./createSelector";
import useQueryUtm from "./queryUtm";
import useInterval from './interval'

const useUtils = () => {
  return {
    useGtm,
    useCreateReducer,
    useCreateSelector,
    useQueryUtm,
    useInterval,
  };
};

export default useUtils;
