import { useEffect } from "react";
import useModels from "../../../../../models";
import useApi from "../../../../../api";

const useCoupons = () => {
  const { useSelectors } = useModels();
  const { useSelector, useCouponSelectors, useUserSelectors } = useSelectors();
  const { couponListSelector } = useCouponSelectors();
  const { logInSelector } = useUserSelectors();
  const { useActions } = useApi();
  const { dispatch, useUserActions } = useActions();
  const { actGetCoupons } = useUserActions();

  const { token: userId } = useSelector(logInSelector);
  const couponList = useSelector(couponListSelector);
  useEffect(() => {
    dispatch(actGetCoupons(userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return {
    couponList,
  };
};

export default useCoupons;
