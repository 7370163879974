import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useCouponServices = () => {
  const { useCouponProviders } = useProviders();
  const { get, register, validate } = useCouponProviders();

  const getCoupon = ({ id }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(get({ id })));
      } catch (e) {
        reject(e);
      }
    });
  };

  const registerCoupon = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(register(data)));
      } catch (e) {
        reject(e);
      }
    });
  };

  const validateCoupon = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(validate(data)));
      } catch (e) {
        reject(e);
      }
    });
  };

  return {
    getCoupon,
    registerCoupon,
    validateCoupon,
  };
};
export default useCouponServices;
