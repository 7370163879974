import useAgeGateTypes from "./ageGate";
import useCouponTypes from "./coupon";
import useSplashTypes from "./splash";
import useMapTypes from "./map";
import useOtpTypes from "./otp";
import useUserTypes from "./user";
import useSessionTypes from "./session";
import useUtmTypes from "./utm";
import useSplashSmsTypes from "./splashSms";
import useOnPremiseTypes from "./onPremise";
import useGeneralTypes from "./general";
const useTypes = () => {
  return {
    useAgeGateTypes,
    useCouponTypes,
    useMapTypes,
    useOtpTypes,
    useUserTypes,
    useSplashTypes,
    useSessionTypes,
    useUtmTypes,
    useSplashSmsTypes,
    useOnPremiseTypes,
    useGeneralTypes,
  };
};

export default useTypes;
