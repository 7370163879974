import _ from 'lodash'

import useStrings from "../../../strings";
import useServices from "../../services";

const useGeneralActions = () => {
  const { useTypes } = useStrings()
  const { useGeneralTypes } = useTypes()
  const { GET_PROVINCES } = useGeneralTypes()

  const { useGeneralServices } = useServices()
  const { fetchProvinces } = useGeneralServices()

  const actGetProvinces = (onSuccess, onError) => async (dispatch) => {
    try {
      const response = await fetchProvinces()
      dispatch({
        type: GET_PROVINCES,
        payload: _.map(
          response.data?.cities, 
          (value) => ({
            value: value.id,
            label: value.name 
          })) ?? []
      })
      onSuccess && onSuccess()
    } catch (error) {
      onError && onError(error)
    }
  }

  return {actGetProvinces}
}

export default useGeneralActions