import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useSplashSmsServices = () => {
  const { useSplashSmsProviders } = useProviders();
  const { getUrlSmsSuccess } = useSplashSmsProviders();

  const getUrlSmsSuccessService = ({ key }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getUrlSmsSuccess({ key })));
      } catch (e) {
        reject(e);
      }
    });
  };
  return {
    getUrlSmsSuccessService,
  };
};

export default useSplashSmsServices;
