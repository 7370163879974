import { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useControllers from "../../..";
import useModels from "../../../../models";
import useApi from "../../../../api";

const useAgeGateMonth = () => {
  const { useSelectors } = useModels();
  const { useSelector, useAgeGateSelectors, useCouponSelectors, useUtmSelectors } = useSelectors();
  const { ageGateSelector } = useAgeGateSelectors();
  const { brandSelector } = useCouponSelectors();
  const { utmSelector } = useUtmSelectors();

  const { useActions } = useApi();
  const { dispatch, useAgeGateActions } = useActions();
  const { actAgeGateCompleted, actAgeGateRememberData } = useAgeGateActions();
  const { useGeneralHooks } = useControllers();
  const { useDataLayers } = useGeneralHooks();
  const { gtmValidateAgeGateMoth } = useDataLayers();

  const [month, setMonth] = useState("");
  const [rememberData, setRememberData] = useState(false);
  const { completedAgeGate, rememberData: rememberDataChbx } = useSelector(ageGateSelector);
  const { name: brandNameCoupon } = useSelector(brandSelector);
  const { value: UTM } = useSelector(utmSelector);

  const [error, setError] = useState("");
  const history = useHistory();
  const location = useLocation();

  const { brandName } = useParams();

  useEffect(() => {
    if (completedAgeGate) history.push(`/${brandName}/form${UTM && "?" + UTM}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedAgeGate]);

  useEffect(() => {
    if (location.state && location.state.rememberData) {
      setRememberData(location.state.rememberData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleRememberData = () => {
    setRememberData(!rememberData);
    dispatch(actAgeGateRememberData(!rememberData));
  };
  const age = (dateString) => {
    const currentMonth = new Date().getMonth();
    return ~~(currentMonth + 1 - parseInt(dateString));
  };
  const handleMonth = (data) => {
    setError("");
    setMonth(data);
  };
  const submitMonth = () => {
    const ageMonth = age(month);

    if (month > 12) {
      return setError("El mes digitado no es valido");
    } else if (month.length === 2 && brandName && location.state && location.state.year) {
      if (ageMonth >= 0) {
        gtmValidateAgeGateMoth({ month, brandName: brandNameCoupon });
        if (ageMonth > 0) {
          dispatch(actAgeGateCompleted(rememberData));
          if (location.state.fromMap) {
            history.push(`/coupon${UTM && "?" + UTM}`);
          } else {
            history.push(`/${brandName}/form${UTM && "?" + UTM}`);
          }
        } else {
          history.push(`/${brandName}/age-gate/day${UTM && "?" + UTM}`, {
            year: location.state.year,
            month,
            rememberData,
            fromMap: location.state.fromMap,
          });
        }
      } else {
        gtmValidateAgeGateMoth({ month, brandName: brandNameCoupon });
        window.location = "https://www.vivamosresponsablemente.com/";
      }
    }
  };
  return {
    error,
    handleMonth,
    submitMonth,
    month,
    rememberData,
    handleRememberData,
    rememberDataChbx,
  };
};

export default useAgeGateMonth;
