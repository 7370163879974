import { useLocation, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import useControllers from "../../../controllers";
import useModels from "../../../models";
import useApi from "../../../api";

const useOtp = () => {
  const { useSelectors } = useModels();
  const {
    useSelector,
    //useUserSelectors,
    useCouponSelectors,
    useSessionSelectors,
    useUtmSelectors,
  } = useSelectors();
  //const { logInSelector } = useUserSelectors();
  const { sessionSelector } = useSessionSelectors();
  const { couponSelectorAux } = useCouponSelectors();
  const { utmSelector } = useUtmSelectors();
  const { value: UTM } = useSelector(utmSelector);

  const { useActions } = useApi();
  const { dispatch, useUserActions, useOtpActions } = useActions();
  const { actPostNewCoupon } = useUserActions();
  const { actValidateOtp, actSendCode } = useOtpActions();
  const { useScreensHooks } = useControllers();
  const { useBrand } = useScreensHooks();

  const history = useHistory();
  const location = useLocation();
  const couponParams = useSelector(couponSelectorAux);
  const session = useSelector(sessionSelector);
  const [userId, setUserId] = useState();
  //const { token } = useSelector(logInSelector);
  const [code, setCode] = useState();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [codeRegister, setCodeRegister] = useState(false);
  const [codeRedi, setCodeRedi] = useState(false);
  const { colors } = useBrand();

  const onValidate = () => {
    dispatch(
      actValidateOtp(
        {
          //id_user: parseInt(userId ?? token),
          id_user: parseInt(userId),
          validation_code: parseInt(code),
          id_coupon: parseInt(couponParams.id),
          UTM,
        },
        (data) => {
          if (data.status_code) {
            if (data.status_code === "001") {
              // TODO: ajustar lógica para saber si es registro o login
              //  con el respectivo gtm
              if (session.process === "register") {
                // if user is new run this code
                const coupon = {
                  //id_user: parseInt(token),
                  id_user: parseInt(userId),
                  id_coupon: parseInt(couponParams.id),
                  UTM,
                };
                dispatch(
                  actPostNewCoupon(coupon, (res) => {
                    switch (res.status_code) {
                      case "004":
                        history.push(`/${session.brand}/success${UTM && "?" + UTM}`, {
                          ...res,
                          stateQR: "Nuevo",
                        });
                        break;
                      default:
                        break;
                    }
                  })
                );
              } else {
                // If user exist, run this
                history.push(`/user/home${UTM && "?" + UTM}`);
              }
            } else if (data.status_code === "004") {
              const couponFromRegister = {
                id_user: parseInt(userId),
                id_coupon: parseInt(couponParams.id),
                UTM,
              };

              dispatch(
                actPostNewCoupon(couponFromRegister, (res) => {
                  switch (res.status_code) {
                    case "004":
                      if (res.url) {
                        window.location.href = res.url;
                        //console.log(res.url);
                      } else {
                        history.push(`/${session.brand}/success${UTM && "?" + UTM}`, {
                          ...res,
                          stateQR: "Nuevo",
                        });
                      }

                      break;
                    default:
                      break;
                  }
                })
              );
            } else if (data.status_code === "002") {
              setCodeRegister(data);
            } else if (data.status_code === "003") {
              setCodeRedi(data);
            }
          }
        }
      )
    );
  };

  const handleCloseCodeRegister = () => {
    setCodeRegister(false);
  };

  const handleCloseCodeRedi = () => {
    setCodeRedi(false);
  };

  const sendOtp = () => {
    dispatch(
      actSendCode({
        id_user: parseInt(userId),
        id_coupon: parseInt(couponParams.id),
        UTM,
      })
    );
  };

  const onChangeInput = (data) => {
    setCode(data);
  };

  useEffect(() => {
    if (location && location.state && location.state.userId) {
      setUserId(location.state.userId);
    }
    if (location && location.state && location.state.email) {
      setEmail(location.state.email);
    }
    if (location && location.state && location.state.phone) {
      setPhone(location.state.phone);
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return {
    onChangeInput,
    onValidate,
    sendOtp,
    email,
    phone,
    codeRedi,
    codeRegister,
    colors,
    handleCloseCodeRedi,
    handleCloseCodeRegister,
  };
};

export default useOtp;
