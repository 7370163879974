import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import useApi from "../../../api";

const useSplashSms = () => {
  const { useActions } = useApi();
  const { dispatch, useSplashSmsActions } = useActions();
  const { actGetUrlSmsSuccess } = useSplashSmsActions();

  const { key } = useParams();
  const { push } = useHistory();

  useEffect(() => {
    dispatch(actGetUrlSmsSuccess({ key }, onSuccessGetUrlSms, onErrorGetUrlSms));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccessGetUrlSms = (data) => {
    const { url } = data;
    url ? (window.location.href = url) : push("/");
  };
  const onErrorGetUrlSms = () => {
    push("/");
  };
  return {};
};

export default useSplashSms;
