import axios from "axios";
import { axioSerempre } from "../instances";

const useShopProviders = () => {
  const get = (data) => {
    return axioSerempre({
      method: "post",
      data,
      url: "nearby_store_stage/dev-serempre",
    });
  };
  const getWithCoupons = (data) => {
    return axios({
      method: "post",
      data,
      url: "tiendas-con-cupon",
    });
  };

  return {
    get,
    getWithCoupons,
  };
};
export default useShopProviders;
