import { useHistory } from "react-router-dom";
import useApi from "../../../../../api";
import useModels from "../../../../../models";

const useCouponDetails = () => {
  const { useActions } = useApi();
  const { dispatch, useCouponActions } = useActions();
  const { actGetCouponToShow } = useCouponActions();
  const { useSelectors } = useModels();
  const { useSelector, useSessionSelectors, useUserSelectors, useUtmSelectors } = useSelectors();
  const { sessionSelector } = useSessionSelectors();
  const { profileSelector } = useUserSelectors();
  const { utmSelector } = useUtmSelectors();
  const { brand } = useSelector(sessionSelector);
  const { name, last_name } = useSelector(profileSelector);
  const { value: UTM } = useSelector(utmSelector);
  const { push } = useHistory();

  const showCouponSelected = ({ brand_id, couponInfo }) => {
    const coupon = {
      id: couponInfo.id,
      status: couponInfo.status,
      name,
      last_name,
      code: couponInfo.coupon_code,
      dateEnd: couponInfo.due_date,
      redeemed_date: couponInfo.redeemed_date,
      qr: couponInfo.coupon_qr,
      titleCoupon: couponInfo.coupon_title,
      grocer_name: couponInfo.grocer_name,
      brand_id: couponInfo.brand_id,
    };
    dispatch(actGetCouponToShow({ id: brand_id, couponInfo: coupon }, onSuccessGetCouponToShow));
  };

  const onSuccessGetCouponToShow = () => {
    push(`/${brand}/success${UTM && "?" + UTM}`);
  };
  return {
    showCouponSelected,
  };
};

export default useCouponDetails;
