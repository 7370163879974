import useAgeGate from "./ageGate";
import useCoupon from "./coupon";
import useFormCoupon from "./form";
import useBrand from "./brand";
import useMap from "./map";
import useOtp from "./otp";
import useSuccess from "./success";
import useTerms from "./tyc";
import useUser from "./user";
import useSplash from "./splash";
import useSuccessRefresh from "./successRefresh";
import useSplashSms from "./splashSms";
import useOnPremise from './onPremise'
import useOnPremiseTimer from './onPremise/timer'
const useScreensHooks = () => {
  return {
    useAgeGate,
    useCoupon,
    useFormCoupon,
    useBrand,
    useMap,
    useOtp,
    useSuccess,
    useTerms,
    useUser,
    useSplash,
    useSuccessRefresh,
    useSplashSms,
    useOnPremise,
    useOnPremiseTimer
  };
};

export default useScreensHooks;
