import useStrings from "../../../strings";
import useServices from "../../services";

const useShopActions = () => {
  const { useTypes } = useStrings();
  const { useMapTypes } = useTypes();
  const { GET_SHOP_MAP, GET_SHOP_MAP_SUCCESS, GET_SHOP_MAP_ERROR } = useMapTypes();

  const { useShopServices } = useServices();
  const { getShop, getWithCouponShop } = useShopServices();

  const actGetShops = (data, id, callback) => async (dispatch) => {
    try {
      dispatch({
        type: GET_SHOP_MAP,
      });
      const res = await getShop(data);
      delete data.zoom;
      const dataForGetWithCouponShop = {
        cupon: id,
        ...data,
      };
      const c = await getWithCouponShop(dataForGetWithCouponShop);
      const pointWCoupons = c.data;
      const points = res.data;
      const havePoints = Object.keys(points).length > 0;

      if (havePoints) {
        const keys = Object.keys(points);
        const pointDec = keys.map((k) => points[k]);

        const payload = pointWCoupons.data.reduce((acc, cv) => {
          const p = pointDec.find((f) => f.code === cv);
          if (p && p !== -1) {
            acc.push(p);
          }
          return acc;
        }, []);
        if (callback) {
          callback(payload);
        }
        dispatch({
          type: GET_SHOP_MAP_SUCCESS,
          payload: payload,
        });
      } else {
        dispatch({
          type: GET_SHOP_MAP_SUCCESS,
          payload: [],
        });
      }
    } catch (e) {
      dispatch({
        type: GET_SHOP_MAP_ERROR,
        error: e,
      });
    }
  };

  return {
    actGetShops,
  };
};

export default useShopActions;
