const useOtpInitialStates = () => {
  const otpInitialState = {
    data: {
      token: null,
    },
  };

  return { otpInitialState };
};

export default useOtpInitialStates;
