import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import useModels from "../../../../models";
import useApi from "../../../../api";

const useFormCoupon = () => {
  const { useSelectors } = useModels();
  const { useSelector, useCouponSelectors, useUtmSelectors, useSessionSelectors } = useSelectors();
  const { couponSelector } = useCouponSelectors();
  const { utmSelector } = useUtmSelectors();
  const { sessionSelector } = useSessionSelectors();
  const { useActions } = useApi();
  const { dispatch, useCouponActions } = useActions();
  const { actGetCouponById, actRegister } = useCouponActions();

  const location = useLocation();
  const history = useHistory();
  const [codeRegister, setCodeRegister] = useState(false);
  const [codeRedi, setCodeRedi] = useState(false);

  const coupon = useSelector(couponSelector);
  const { value: UTM } = useSelector(utmSelector);
  const { brand } = useSelector(sessionSelector);
  const indicative = [{ value: "57", label: "+57" }];

  const handleCloseCodeRegister = () => {
    setCodeRegister(false);
  };

  const handleCloseCodeRedi = () => {
    setCodeRedi(false);
  };
  const submit = (data) => {
    const { email, name, lastName, phone, tyc, indicative } = data;
    if (location && location.state && location.state.id) {
      const post = {
        id_coupon: parseInt(location.state.id),
        name,
        last_name: lastName,
        phone: parseInt(`${indicative.value}${phone}`),
        email,
        enrollment: tyc ? 1 : 0,
      };
      dispatch(
        actRegister(post, (response) => {
          switch (response.status_code) {
            case "011":
              history.push(`/${brand}/otp${UTM && "?" + UTM}`, {
                couponId: location.state.id,
                userId: response.uid,
                email: email,
                phone: phone,
              });
              break;
            case "001":
              history.push(`/${brand}/otp${UTM && "?" + UTM}`, {
                couponId: location.state.id,
                userId: response.id_user,
                email: email,
                phone: phone,
              });
              break;
            case "002":
              setCodeRegister(response);
              break;
            case "003":
              setCodeRedi(response);
              break;
            default:
              break;
          }
        })
      );
    }
  };

  useEffect(() => {
    if (location && location.state && location.state.id) {
      dispatch(actGetCouponById({ id: location.state.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, dispatch]);

  return {
    ...coupon,
    indicative,
    codeRegister,
    codeRedi,
    handleCloseCodeRegister,
    handleCloseCodeRedi,
    submit,
  };
};

export default useFormCoupon;
