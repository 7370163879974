import { trackPromise } from "react-promise-tracker";

import useProviders from "../../providers";

const useGeneralServices = () => {
  const { useGeneralProviders } = useProviders();
  const { fetchProvincesProvider } = useGeneralProviders();

  const fetchProvinces = () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(fetchProvincesProvider()));
      } catch (error) {
        reject(error);
      }
    });
  };

  return { fetchProvinces };
};

export default useGeneralServices;
